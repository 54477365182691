import React from 'react';

import Header from '../../components/Header';
import Notifs from '../../components/Notifs/Notifs';

import styles from './UserNotifs.module.scss';

const UserNotifs = () => (
    <div className={styles.container}>
      <Header name='Notifications' isProfile />
      <div className={styles.content}>
        <Notifs />
      </div>
    </div>
);

export default UserNotifs;
