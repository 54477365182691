import { combineReducers, createStore } from 'redux';
import authReducer from './authReducer';
import structureReducer from './structureReducer';
import usersReducer from './usersReducer';
import shiftsReducer from './shiftsReducer';
import notifsReducer from './notifsReducer';
import contractsReducer from './contractReducer';
import holidayReducer from './holidayReducer';
import notesReducer from './notesReducer';

const rootReducer = combineReducers({
  authReducer,
  structureReducer,
  usersReducer,
  shiftsReducer,
  notifsReducer,
  contractsReducer,
  holidayReducer,
  notesReducer,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
