import React, { useEffect, useState } from 'react';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useForm, useFormContext, get } from 'react-hook-form';
import { ErrorField, InputColor, InputText } from '../../lib/HooksFormFields';
import SelectIcons from '../../lib/HooksFormFields/SelectIcons';
import { IPole } from '../../types/structure';
// import Icon from '../Icon/Icon';
import styles from './PoleModal.module.scss';

const PoleForm = ({ pole, onSubmit }: { pole: IPole, onSubmit: any }) => {
  const {
    control, reset, formState: { errors }, setValue, handleSubmit, watch,
  } = useForm();
  const form = watch();
  const [activity, setActivity] = useState({
    label: '',
    value: '',
    color: pole.color,
  });

  async function handleChangeIsActive() {
    onSubmit({ ...form, isActive: !form.isActive });
  }

  useEffect(() => {
    if (pole) {
      reset(pole);
    }
  }, []);

  return (
    <div className={styles.form}>
       <div className={styles['container-field']}>
          <InputText
            name="name"
            control={control}
            label="Nom de la catégorie"
            rules={{
              required: 'Veuillez renseigner le nom',
            }}
          />
        {typeof get(errors, 'name')?.message === 'string' && (
          <ErrorField message={get(errors, 'name')?.message || ''}/>
        )}
      </div>
      <div className={styles['container-field']}>
          <InputColor
            name="color"
            control={control}
            label="Couleur"
            rules={{
              required: 'Veuillez renseigner la couleur',
            }}
          />
        {typeof get(errors, 'color')?.message === 'string' && (
          <ErrorField message={get(errors, 'color')?.message || ''}/>
        )}
      </div>
      <div className={styles['container-field']}>
        <div className={styles.icon}>
          <label>
            Icône
          </label>
          <SelectIcons
            name="icon"
            control={control}
            color={form.color}
            defaultValue={form.icon}
            rules={{}}
          />
        </div>
      </div>
      <div className={styles.activities}>
        <h2>
          Activités de la catégorie
        </h2>
        <div className={styles.input}>
          <input
            value={activity?.label}
            placeholder="Nom de l’activité"
            onChange={(e) => setActivity({
              label: e.target.value,
              color: pole.color,
              value: e.target.value,
            })}
          />
          <button
            type="button"
            className={activity.label ? styles.active : ''}
            onClick={() => {
              setValue('activities', [...form.activities, activity]);
              setActivity({
                label: '',
                value: '',
                color: pole.color,
              });
            }}
          >
          + Ajouter
          </button>
        </div>
        <div className={styles.list}>
          {form?.activities?.map((a: any, i: number) => <div
              key={`activity-${i}`}
              className={styles.activity}>
              <p>{a.label}</p>
              <div className={styles.color}>
                <InputColor
                  name={`activities.${i}.color`}
                  control={control}
                  className={'small'}
                  rules={{
                  }}
                />
              </div>
              <button
                type='button'
                onClick={() => setValue('activities', form.activities.filter((ac: string, index: number) => i !== index))}
              >
                <RiDeleteBin5Line />
              </button>
            </div>)}
        </div>

      </div>
      <div className={styles.actions}>
        <button
          type='button'
          onClick={handleSubmit(onSubmit)}
        >
          {pole._id ? 'Modifier la catégorie' : 'Créer une nouvelle catégorie'}
        </button>
        <button
          type="button"
          onClick={() => handleChangeIsActive()}
        >
        {form.isActive ? 'Archiver la catégorie' : 'Activer la catégorie'}
        </button>
      </div>
    </div>
  );
};

const PoleModal = ({
  onSubmit,
  pole,
  close,
}: {
  onSubmit: any,
  pole: IPole,
  close: () => void
}) => {
  const { watch } = useFormContext<any>();
  const form = watch();

  async function handleChange(p: IPole) {
    const poles = form.poles || [];
    const index = form.poles.findIndex((f : IPole) => f._id === p?._id);
    if (index !== -1) {
      poles[index] = p;
      await onSubmit({ poles });
      return close();
    }
    await onSubmit({ poles: [...poles, p] });
    return close();
  }
  return (
    <div className={styles.modale}>
      <div className={styles.title}>
        <h2>
          {pole._id ? pole?.name : 'Créer une nouvelle catégorie'}
        </h2>
        {!pole.isActive && <p className={styles.archived}>(Archivé)</p>}
      </div>
      <PoleForm
        pole={pole}
        onSubmit={(p :IPole) => handleChange(p)}
      />
    </div>
  );
};

export default PoleModal;
