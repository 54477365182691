import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postUsersFilteredAction, updateUserAction } from '../../actions/users';

import { IUser } from '../../types/user';

import styles from './AdminModal.module.scss';

const UserCard = ({
  user,
  className = null,
  setSelected,
}:{
  user : IUser,
  className?: string | null
  setSelected: () => void,
}) => (
    <div
      onClick={() => setSelected()}
      className={`
      ${styles.card}
      ${className ? styles[className] : ''}
      ${user.isActive ? '' : styles.archived}
    `}>
      <div className={styles.picture}>
        {user?.picture?.path
          ? <img src={`${process.env.REACT_APP_API_URL}/public/${user.picture.path}`} alt={user.picture.alt}/>
          : <p>{user?.profile?.firstName[0]}.{user?.profile?.lastName[0]}</p>
        }
      </div>
      <div className={styles.infos}>
        <h3>{user?.profile?.firstName} {user?.profile?.lastName}</h3>
        {user?.structure?.name && <h4>{user?.structure?.name}</h4>}
        {user.role === 'Admin' && <h4>Administrateur</h4>}
      </div>
    </div>
);

const AdminModal = ({ close }: { close: () => void }) => {
  const { usersList } = useSelector((store: any) => store.usersReducer);
  const { structure } = useSelector((store: any) => store.structureReducer);
  const users = usersList?.filter((u : IUser) => (u.role === 'Member' || u.role === 'Manager') && u.isActive && u.structure?._id === structure._id);
  const [selected, setSelected] = useState<string | null>(null);
  const dispatch = useDispatch();

  async function handleAddAdmin() {
    if (!selected) return;
    await updateUserAction(dispatch, { role: 'Owner', poles: [] }, selected);
    await postUsersFilteredAction(dispatch, { structure: structure._id, search: '' });
    setSelected(null);
    close();
  }

  return (
    <div className={styles.modale}>
      <h2>
        Ajouter un membre à la direction
      </h2>
      <div className={styles.users}>
        {users?.map((u : IUser) => <UserCard
          key={u._id}
          user={u}
          className={u._id === selected ? 'active' : ''}
          setSelected={() => setSelected(u._id)}
        />)}
      </div>
      <div className={styles.actions}>
        <button
          type='button'
          className={!selected ? styles.disabled : ''}
          onClick={() => handleAddAdmin()}
        >
           Ajouter
        </button>
      </div>
    </div>
  );
};

export default AdminModal;
