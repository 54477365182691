import React, { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import img from '../../assets/images/login.svg';
import logo from '../../assets/images/omada_bleu.svg';
import { resetPassword } from '../../actions/auth';
import ResetPwdForm from '../../components/form/AuthForm/ResetPwdForm';

import styles from './reset-password.module.scss';

const ResetPassword = () => {
  const authReducer = useSelector((d: any) => d.authReducer);
  const [succeed, setSucceed] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authReducer.user?._id) navigate('/pages/center');
  }, [authReducer.user]);

  function submitReset(data: FieldValues) {
    resetPassword(dispatch, {
      password: data.password,
      token: params?.token || '',
    }).then((res: any) => {
      if (res?.status === 200) setSucceed(true);
    });
  }

  return (
    <div className={styles['reset-password']}>
      <div className={styles.picture}>
        <img src={img} alt="logo" />
      </div>
      <div className={styles.content}>
         <div className={styles.form}>
          <div className={styles.logo}>
            <img src={logo} alt='omada'/>
          </div>
          <h3>Mot de passe oublié</h3>
          <ResetPwdForm
            submit={submitReset}
            signinLink={succeed ? '/' : null}
            succeedMessage={
              succeed ? 'Votre mot de passe a été mis à jour.' : null
            }
            errorMessage={authReducer.error}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
