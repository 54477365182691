import React, { useEffect } from 'react';
import { useForm, FieldValues, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { BsCheck } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';

import { FaPlus } from 'react-icons/fa';
import { format } from 'date-fns';
import Header from '../../components/Header';

import styles from './Contracts.module.scss';
import { getUserAction } from '../../actions/users';
import InputMedia from '../../lib/HooksFormFields/InputMedia';
import { IStructure } from '../../types/structure';
import { getAllStructuresAction } from '../../actions/structures';
import {
  createContractAction, getContractAction, getUserContractsAction, updateContractAction,
} from '../../actions/contract';
import { IContract, Period } from '../../types/contract';
import { CONTRACT_ERROR, GET_CONTRACT, SET_TOAST } from '../../actions/types';
import ContractForm from '../../components/ContratForm/ContractForm';

const ContractRow = ({ contract } : { contract: IContract }) => {
  const startDate = contract?.startDate && new Date(contract?.startDate);
  const endDate = contract?.endDate && new Date(contract?.endDate);
  const dispatch = useDispatch();
  return (
    <div className={`${styles.row}`}
      onClick={() => getContractAction(dispatch, contract._id)}
    >
      <div className={styles.col}>
        {contract?.type}
      </div>
      <div className={styles.col}>
        {contract?.startDate && format(new Date(contract?.startDate), 'dd/MM/yyyy')}
      </div>
      <div className={styles.col}>
        {contract?.endDate && format(new Date(contract?.endDate), 'dd/MM/yyyy')}
      </div>
      <div className={styles.col}>
        {contract?.isAnnualized ? 'Annualisé' : ''}
      </div>
      <div className={styles.col}>

      </div>
      <div className={styles.col}>
        {(startDate < new Date() && !endDate) || (startDate < new Date() && endDate > new Date()) ? 'En cours' : ''}
      </div>
    </div>
  );
};

const Contracts = () => {
  const id = useParams()?.id;

  const { user } = useSelector((store: any) => store.usersReducer);
  const { contract, userContracts, error } = useSelector((store: any) => store.contractsReducer);
  const { structuresList } = useSelector((store: any) => store.structureReducer);
  const structure = structuresList?.find((s: IStructure) => s._id === user?.structure?._id);
  const dispatch = useDispatch();
  const methods = useForm();

  const onSubmit = async (data : FieldValues) => {
    const formData = { ...data };
    if (formData.startDate) {
      formData.startDate = new Date(`${format(formData.startDate, 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString();
      if (contract.startDate && formData.startDate !== contract.startDate) {
        return methods.setError('startDate', { type: 'custom', message: 'La date de début ne peut pas être modifiée' });
      }
    }
    if (formData.endDate) {
      formData.endDate = new Date(`${format(formData.endDate, 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString();
      if (contract.endDate && formData.endDate !== contract.endDate) {
        return methods.setError('endDate', { type: 'custom', message: 'La date de fin ne peut pas être modifiée' });
      }
    }
    if (formData?.periods) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [i, period] of formData.periods.entries()) {
        const endDate = new Date(`${format(period.endDate, 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString();
        const startDate = new Date(`${format(period.startDate, 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString();
        formData.periods[i] = {
          ...period,
          endDate,
          startDate,
        };
      }
    }
    if (!formData._id) {
      await createContractAction(dispatch, formData);
    } else {
      await updateContractAction(dispatch, formData._id, formData);
    }
    return null;
  };

  useEffect(() => {
    if (id) {
      getUserAction(dispatch, id);
      getAllStructuresAction(dispatch);
      getUserContractsAction(dispatch, id);
    }
  }, [id]);

  useEffect(() => {
    if (contract) {
      const contractForm = { ...contract };
      if (contractForm?.startDate) {
        contractForm.startDate = format(new Date(contract.startDate), 'yyyy-MM-dd');
      }
      if (contractForm?.endDate) {
        contractForm.endDate = format(new Date(contract.endDate), 'yyyy-MM-dd');
      }
      if (contractForm?.periods) {
        // eslint-disable-next-line no-restricted-syntax
        contractForm.periods = contractForm.periods.map((period: Period) => {
          const endDate = format(period.endDate, 'yyyy-MM-dd');
          const startDate = format(period.startDate, 'yyyy-MM-dd');
          return {
            ...period,
            endDate,
            startDate,
          };
        });
      }
      methods.reset({ ...contractForm });
    } else {
      methods.reset({});
    }
  }, [contract]);

  useEffect(() => {
    if (error?.data?.error === 'A slot already exists for this contract, it cannot be modified') {
      dispatch({
        type: SET_TOAST,
        payload: {
          type: 'error',
          message: 'Un créneau existe déjà pour ce contrat, il ne peut pas être modifié',
        },
      });
      dispatch({
        type: CONTRACT_ERROR,
        payload: null,
      });
    }
  }, [error]);

  return (
    <div className={styles.container}>
      <Header name='Mon profil'/>
      <div className={styles.main}>
        {contract && user
          && <button className={styles.edit} onClick={methods.handleSubmit(onSubmit)}>
            <div className={styles.icon}>
              <BsCheck size={28}/>
            </div>
            <span>Sauvegarder</span>
          </button>
        }
        <div className={styles.container}>
          <div className={styles.bg}/>
          <div className={styles.content}>
            <div className={styles.avatar}>
              <InputMedia
                name="picture"
                media={user?.picture}
                disabled={true}
              />
              <div className={styles.name}>
                <h1>{user?.profile?.firstName}
                {' '}
                {user?.profile?.lastName}</h1>
                {structure?.name
                && <p>{structure?.name}</p>}
              </div>
            </div>
            <div className={styles.form}>
              <div className={styles.links}>
                <Link to={`/admin/user/${user?._id}`} className={styles.link}>
                  Informations
                </Link>
                <div className={styles.link}
                  onClick={() => dispatch({
                    type: GET_CONTRACT,
                    payload: null,
                  })}
                >
                  Contrats
                </div>
              </div>
              {!contract && user?.structure?._id
                && <button
                  type='button'
                  className={styles.edit}
                  onClick={() => dispatch({
                    type: GET_CONTRACT,
                    payload: {
                      structure: user?.structure?._id,
                      isAnnualized: false,
                      user: user._id,
                      periods: [],
                    },
                  })}
                >
                  <div className={styles.icon}>
                    <FaPlus size={16}/>
                  </div>
                  <span>Ajouter un contrat</span>
                </button>
                }
              {contract
                ? <FormProvider {...methods} >
                    <ContractForm
                    />
                </FormProvider>
                : <div className={styles.list}>
                   {userContracts.length > 0
                    && <div className={`${styles.row} ${styles.labels}`}>
                      <div className={styles.col}>
                        Type
                      </div>
                      <div className={styles.col}>
                        Date de début
                      </div>
                      <div className={styles.col}>
                        Date de fin
                      </div>
                      <div className={styles.col}>
                        Catégorie
                      </div>
                      <div className={styles.col}>

                      </div>
                      <div className={styles.col}>
                        Statut
                      </div>
                    </div>
                    }
                    {userContracts.sort(
                      (a:IContract, b: IContract) => (
                        new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
                      ),
                    )
                      ?.map((c: IContract) => <ContractRow
                      key={c?._id}
                      contract={c}
                    />)}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contracts;
