import React from 'react';
import { Controller } from 'react-hook-form';
import ErrorField from '../ErrorField';
import styles from './Checkbox.module.scss';

interface ICheck {
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any,
  label?: string | null,
  inline?: boolean,
  widthLabel?: string | null,
  error?: string | null,
  placeholder?: string,
  defaultValue?: boolean,
  className?: string,
}

function Checkbox({
  name,
  control,
  rules,
  defaultValue = false,
  widthLabel = null,
  label = null,
  placeholder,
  error = null,
  className = 'primary',
}: ICheck) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: {
          // name,
          value = '',
          ref,
          onChange,
          onBlur,
        },
      }) => (
        <>
          <div className={`${className ? styles[className] : ''} ${error ? styles.error : ''} ${styles['container-input']}`}>
            <input
              ref={ref}
              value={value}
              type="checkbox"
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.checked)}
              className={styles.input}
              placeholder={placeholder}
            />
            {label && <span
            className={styles.label}
            style={widthLabel ? { minWidth: widthLabel } : {}}>
            {label}
          </span>}
            <button
              onClick={() => onChange(!value)}
              className={`${styles.checkmark} ${value ? styles.checked : ''}`}>
            </button>
          </div>
          {error && <ErrorField message={error}/>}
        </>
      )}
    />
  );
}

export default Checkbox;
