import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createNotifAction, updateNotifAction } from '../../actions/notif';
import { SET_NOTIF_IS_OPEN, SET_USERS_FILTERS } from '../../actions/types';
import { INotif, INotifPost } from '../../types/notif';
import styles from './Notifs.module.scss';

const Notification = ({ notif }: { notif: INotif }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((d: any) => d.authReducer);
  const role = user?.role;
  const { filters } = useSelector((store: any) => store.usersReducer);
  const updateFormated = notif?.createdAt && new Intl.DateTimeFormat('fr', {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(notif?.createdAt));

  const dateFormated = notif?.date && new Intl.DateTimeFormat('fr', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(notif?.date));

  function handleChangeFilters(obj: any) {
    dispatch({
      type: SET_USERS_FILTERS,
      payload: {
        ...filters,
        ...obj,
      },
    });
  }

  async function handleRedirectDate() {
    if (role !== 'Member') {
      await handleChangeFilters({
        startDate: notif.date,
        endDate: null,
        structure: notif?.structure && notif?.structure?._id,
        poles: notif.author.poles,
      });
      return navigate('/admin/planning');
    }
    const date = new Date(notif.date);
    const dayOfWeek = new Date(notif.date).getDay();
    const diff = (dayOfWeek === 1) ? 0 : dayOfWeek - 1;
    const monday = diff === -1
      ? new Date(new Date(date).setDate(date.getDate() - 6))
      : new Date(new Date(date).setDate(date.getDate() - diff));
    const sunday = new Date(new Date(date).setDate(monday.getDate() + 6));
    await handleChangeFilters({
      startDate: monday.toISOString(),
      endDate: sunday.toISOString(),
      structure: notif?.structure && notif?.structure?._id,
    });
    await updateNotifAction(dispatch, { isRead: true }, notif._id);
    return navigate(`/member/planning/${user._id}`);
  }

  async function handleValidUpdate(bool : boolean) {
    const data : INotifPost = {
      user: notif.author._id,
      message: 'Demande accepté',
      structure: notif.structure._id,
      date: notif.date,
      request: notif.message,
    };
    if (bool === false) {
      data.message = 'Demande refusée';
    }
    await createNotifAction(dispatch, data);
    await updateNotifAction(dispatch, { isRead: true }, notif._id);
  }

  return (
    <div className={styles.notif}>
      <div className={styles.author}>
        {notif?.author?.picture?.path
          ? <>
            <img src={`${process.env.REACT_APP_API_URL}/public/${notif?.author.picture?.path}`} alt='avatar' />
          </>
          : <p>{notif?.author?.profile?.firstName[0]}.{notif?.author?.profile?.lastName[0]}</p>
        }
      </div>
      <div className={styles.message}>
        <p>{notif?.author?.profile?.firstName} {notif?.author?.profile?.lastName}</p>
        <p className={styles.message}>{notif.message}</p>
        {notif?.request && <p>{notif.request}</p>}
        <div className={styles.actions}>
            {notif.type === 'dateUpdate' && !notif.isRead
            && <div className={styles.valid}>
              <button
                type="button"
                onClick={() => handleValidUpdate(true)}
              >
                Accepter
              </button>
              <button
                type="button"
                onClick={() => handleValidUpdate(false)}
              >
                Refuser
              </button>
            </div>
          }
          <button
            type="button"
            onClick={() => handleRedirectDate()}
            className={styles.redirect}>
            voir le planning du {dateFormated}
          </button>
        </div>

        <p>{updateFormated}</p>
      </div>
    </div>
  );
};

export default function Notifs() {
  const dispatch = useDispatch();
  const { notifs, isOpen } = useSelector((d: any) => d.notifsReducer);
  const [isNew, setIsNew] = useState(true);

  const newNotifs = notifs?.filter((n: INotif) => !n.isRead);

  const oldNotifs = notifs?.filter((n: INotif) => n.isRead);

  const currentNotifs = isNew ? newNotifs : oldNotifs;

  useEffect(() => {
    if (!isNew && newNotifs.length > 0) {
      setIsNew(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isNew && newNotifs.length === 0) {
      setIsNew(false);
    }
  }, [newNotifs]);

  return (
    <div className={styles.container} onMouseLeave={() => dispatch({
      type: SET_NOTIF_IS_OPEN,
      payload: false,
    })}>
      <div className={styles.toggle}>
        {newNotifs.length > 0
            && <button
            type='button'
            className={isNew ? styles.active : ''}
            onClick={() => setIsNew(true)}
          >
            <p>Nouvelles</p>
            <div className={`${styles.count} ${newNotifs?.length > 0 ? styles.active : ''}`}>
              {newNotifs.length}
            </div>
          </button>
        }
        {oldNotifs.length > 0
          && <button
            type='button'
            className={!isNew ? styles.active : ''}
            onClick={() => setIsNew(false)}
          >
            <p>Déja lues</p>
          </button>
        }
      </div>
      <div className={styles.list}>
        {currentNotifs.map((n: INotif) => <Notification key={n._id} notif={n}/>)}
      </div>
    </div>
  );
}
