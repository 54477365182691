import {
  GET_ALL_NOTIFS,
  NOTIF_LOADING,
  NOTIF_ERROR,
  SET_NOTIF_IS_OPEN,
  UPDATE_NOTIF,
} from '../actions/types';
import { ActionReducer } from '../types';
import { INotif } from '../types/notif';

interface NotifsState {
  notifs: INotif[],
  isLoading: string[],
  isOpen: boolean,
  error: string | null,
}

const DEFAULT_STATE: NotifsState = {
  notifs: [],
  isOpen: false,
  isLoading: [],
  error: null,
};

// eslint-disable-next-line default-param-last
const notifsReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  const { notifs } = state;
  const notifsIndex = notifs?.findIndex((n) => n?._id === action.payload?._id);
  switch (action.type) {
    case GET_ALL_NOTIFS:
      updatedState = {
        ...state,
        notifs: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case UPDATE_NOTIF:
      if (notifsIndex !== -1) {
        notifs[notifsIndex] = action.payload;
      }
      updatedState = {
        ...state,
        notifs,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case NOTIF_LOADING:
      updatedState = {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case SET_NOTIF_IS_OPEN:
      updatedState = { ...state, isOpen: action.payload };
      break;
    case NOTIF_ERROR:
      updatedState = { ...state, error: action.payload };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default notifsReducer;
