import React, {
  useRef,
} from 'react';
import axios from 'axios';
import { FaCamera } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { deleteFile } from '../../../actions/file';

// Styles
import styles from './media.module.scss';
import { FileType } from '../../../types/file';

function InputMedia({
  className = 'profile',
  name,
  media,
  submit,
  disabled,
}:{
  name: string,
  className?: string,
  media: FileType | null,
  disabled: boolean,
  handleChange?: (value: any) => void,
  submit?: (value: any) => void,
}) {
  const inputRef = useRef<null>(null);
  const dispatch = useDispatch();

  async function handleChangeFile(e : any) {
    const fileData = e.target.files[0];
    if (!submit) {
      return null;
    }
    if (media?._id) deleteFile(dispatch, media._id);
    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('name', fileData.name);
    formData.append('alt', fileData.name.split('.')[0]);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token');
      const config: any = {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      axios.post(`${process.env.REACT_APP_API_URL}/files`, formData, config)
        .then((response : any) => {
          resolve(response.data);
          submit({ [name]: response.data.file._id });
        })
        .catch((error: any) => {
          reject(error);
          console.log(error);
        });
    });
  }
  return (
    <div className={`${styles.container} ${styles[className]}`}>
      <div className={styles.img}>
        {media?.path
          && <>
            {media.mimetype === 'video/mp4'
              ? <video controls>
                <source
                  src={`${process.env.REACT_APP_API_URL}/public/${media.path}`}
                  type={media.mimetype}>
                </source>
              </video>
              : <img src={`${process.env.REACT_APP_API_URL}/public/${media.path}`} alt={media.alt}/>
            }

          </>
         }
      </div>
      {!disabled
      && <div className={styles.input}>
        <label htmlFor={name}><FaCamera /></label>
        <input
          id={name}
          type='file'
          onChange={(e) => handleChangeFile(e)}
          ref={inputRef}
        />
      </div>
      }
    </div>
  );
}

export default InputMedia;
