import axios from 'axios';
import { Dispatch } from 'redux';
import {
  postData,
  getData,
  putData,
} from './index';
import {
  AUTH_SIGNIN,
  AUTH_SIGNUP,
  AUTH_GET_PROFILE,
  AUTH_PUT_PROFILE,
  AUTH_LOGOUT,
  AUTH_LOADING,
  AUTH_RESET_ERROR,
  AUTH_ERROR,
  SET_TOAST,
} from './types';

export interface RequestFunction {
  (
    dispatch: Dispatch,
    data: any,
  ) : any
}

export const signUp: RequestFunction = async (dispatch, data) => {
  const url = '/auth/register';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await postData(AUTH_ERROR, url, dispatch, data, false);
  const user = response?.data?.user;
  const token = response?.data?.token;

  if (user && token) {
    dispatch({
      type: AUTH_SIGNUP,
      payload: { user, token },
    });
  }
  return response;
};

export const signIn: RequestFunction = async (dispatch, data) => {
  const url = '/auth/login';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await postData(AUTH_ERROR, url, dispatch, data, false);
  const user = response?.data?.user;
  const token = response?.data?.token;

  if (user && token) {
    dispatch({
      type: AUTH_SIGNIN,
      payload: { user, token },
    });
  }
  return response;
};

export const forgotPassword: RequestFunction = async (dispatch, data) => {
  const url = '/auth/forgot-password';
  const response = await postData(AUTH_ERROR, url, dispatch, data, false);
  return response;
};

export const resetPassword: RequestFunction = async (dispatch, data) => {
  const url = `/auth/reset-password/${data.token}`;
  const response = await postData(AUTH_ERROR, url, dispatch, { password: data.password }, false);
  return response;
};

export const logout = (dispatch: Dispatch) => {
  dispatch({
    type: AUTH_LOGOUT,
  });
};

export const completeUserProfile = async (data: any) => {
  const url = `${process.env.REACT_APP_API_URL}/users/register/${data.token}`;
  const { token, ...udpdatedData } = data;
  let response;
  try {
    const config = {};
    response = await axios.put(url, udpdatedData, config);
  } catch (err) {
    console.log(err);
  }
  return response;
};

export const getProfileAction = async (dispatch: Dispatch) => {
  const url = '/users/profile';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await getData(AUTH_ERROR, url, dispatch, true);
  if (response?.data?.user) {
    dispatch({
      type: AUTH_GET_PROFILE,
      payload: response.data?.user,
    });
  }
};

export const updateProfile: RequestFunction = async (dispatch, data) => {
  const url = '/users/profile';
  dispatch({
    type: AUTH_LOADING,
    payload: AUTH_PUT_PROFILE,
  });
  const response = await putData(AUTH_ERROR, url, dispatch, data, true);

  if (response?.data?.user) {
    dispatch({
      type: AUTH_PUT_PROFILE,
      payload: response.data.user,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'Votre profil a été modifié',
        type: 'success',
      },
    });
  }
  return response;
};

export const resetError = (dispatch: Dispatch) => {
  dispatch({
    type: AUTH_RESET_ERROR,
  });
};
