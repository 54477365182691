import { Dispatch } from 'redux';
import { getData, postData, putData } from '.';
import {
  CREATE_USER,
  GET_ALL_USERS, GET_USER, SET_TOAST, UPDATE_USER, USER_ERROR, USER_LOADING,
} from './types';

export const postUsersFilteredAction = async (dispatch: Dispatch, data: any) => {
  const url = '/users/filtered';
  dispatch({
    type: USER_LOADING,
  });
  const response = await postData(USER_ERROR, url, dispatch, data, true);
  if (response?.data?.users) {
    dispatch({
      type: GET_ALL_USERS,
      payload: response.data.users,
    });
  }
};

export const getUserAction = async (dispatch: Dispatch, id: string) => {
  const url = `/users/${id}`;
  dispatch({
    type: USER_LOADING,
  });
  const response = await getData(USER_ERROR, url, dispatch, true);
  if (response.data.user) {
    dispatch({
      type: GET_USER,
      payload: response.data.user,
    });
  }
};

export const updateUserAction = async (dispatch :Dispatch, data: any, id: string) => {
  const url = `/users/${id}`;
  dispatch({
    type: USER_LOADING,
  });
  const response = await putData(USER_ERROR, url, dispatch, data, true);

  if (response.data?.user) {
    dispatch({
      type: UPDATE_USER,
      payload: response.data?.user,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'L\'utilisateur a été modifié',
        type: 'success',
      },
    });
  }
  return response;
};

export const createUserAction = async (dispatch :Dispatch, data: any) => {
  const url = '/users';
  dispatch({
    type: USER_LOADING,
  });
  const response = await postData(USER_ERROR, url, dispatch, data, true);

  if (response?.data?.user) {
    dispatch({
      type: CREATE_USER,
      payload: response.data?.user,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'L\'utilisateur a été créé',
        type: 'success',
      },
    });
  }
  return response;
};
