import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import uniqid from 'uniqid';
import { useSelector } from 'react-redux';
import { IShift } from '../../types/shift';
import styles from './TimeLine.module.scss';
import { getDurationLabel, hexToRgba } from '../../utils';

type IStep = {
  id: string,
  color: string,
  className: string,
  width: number,
  left: number,
  duration?: number,
  label?: string,
  start?: string,
  end?: string,
};

const Step = ({
  step,
}:{
  step: IStep
}) => (
    <div className={styles.step} style={{ height: `${step.width}px` }}>
      <div
        className={styles.content}
        style={{
          backgroundColor: hexToRgba(step.color, 0.8),
        }}
      >
        <div className={`${styles.label} ${step.duration && step.duration < 1.5 ? styles.line : ''} ${step.label === 'congé' || step.label === 'maladie' || step.className === 'empty' ? styles.empty : ''}`}>
          <p>{step?.label}</p>
          {step?.start && step?.end && <p>{step?.start} - {step?.end}</p>}
          <p>{step.duration && getDurationLabel(step.duration)}</p>
        </div>
      </div>
    </div>
);

export default function TimeLine({
  shifts = [],
}:{
  shifts: IShift[]
}) {
  const [steps, setSteps] = useState<IStep[]>([]);
  const { list } = useSelector((store: any) => store.shiftsReducer);

  useEffect(() => {
    if (list?.shifts) {
      const updatedSlices : IStep[] = [];
      const stepWidth = 3;
      let empties : string[] = [];
      let slicesFound : string[] = [];
      const left = 0;
      if (shifts?.length > 0) {
        list.shifts.forEach((t: any) => {
          const foundIndex = shifts.findIndex((s) => s.slices[0] === t.label);
          let slices : any = null;
          if (foundIndex !== -1) {
            const shift = shifts[foundIndex];
            if (shift.slices.length > 0) {
              const endShiftIndex = list.shifts
                .findIndex((s: any) => s.label === shift?.slices[shift.slices.length - 1]);
              slices = {
                id: uniqid(),
                label: shift.activity,
                start: shift.slices[0],
                end: list.shifts[endShiftIndex + 1]?.label,
                duration: (shift.slices.length * 5) / 60,
                color: shift.structure?.poles.find((p : any) => p._id === shift.pole)?.color
                || list?.activitiesOptions?.find((a: any) => a.value === shift.activity)?.color,
                className: 'step',
                width: shift.slices.length * stepWidth,
                left,
              };
              slicesFound = [...slicesFound, ...shift.slices];
            }
            if (empties.length > 0) {
              const endShiftIndex = list.shifts
                .findIndex((s: any) => s.label === empties[empties.length - 1]);
              updatedSlices.push({
                id: uniqid(),
                label: 'Pause',
                color: '#EDF2F9',
                className: 'empty',
                start: empties[0],
                end: list.shifts[endShiftIndex + 1]?.label,
                duration: (empties.length * 5) / 60,
                left,
                width: empties.length * stepWidth,
              });
              empties = [];
            }
            if (slices) {
              updatedSlices.push(slices);
            }
          } else if (!slicesFound.find((s) => s === t.label)) {
            empties.push(t.label);
            slicesFound.push(t.label);
          }
        });
        if (empties.length > 1) {
          updatedSlices.push({
            id: uniqid(),
            color: '#EDF2F9',
            className: 'empty',
            left,
            width: empties.length * stepWidth,
          });
        }
        setSteps(updatedSlices);
        const lists = updatedSlices.filter((s: IStep, i: number) => {
          if ((i === 0 || i === updatedSlices.length - 1) && s.className === 'empty') {
            return null;
          }
          return s;
        });
        setSteps(lists);
      } else {
        setSteps([]);
      }
    }
  }, [list?.shifts, shifts]);
  return (
    <div className={styles.container}>
      {steps.map((s) => (
        <Step
          key={s.id}
          step={s}
        />
      ))}
    </div>
  );
}
