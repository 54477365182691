import {
  GET_USER,
  CREATE_USER,
  UPDATE_USER,
  GET_ALL_USERS,
  USER_LOADING,
  USER_ERROR,
  SET_USERS_FILTERS,
} from '../actions/types';
import { ActionReducer } from '../types';
import { IUser } from '../types/user';

export interface IFilters {
  structure: null | string,
  poles: [],
  search: string,
  sort: string,
  sortDir: string,
  startDate: string,
}

interface UsersState {
  user: IUser | null,
  usersList: IUser[],
  isLoading: string[],
  error: string | null,
  filters: IFilters,
}

const startDate = new Date(new Date().setUTCHours(0, 0, 0, 0));

const DEFAULT_STATE: UsersState = {
  user: null,
  usersList: [],
  isLoading: [],
  error: null,
  filters: {
    structure: null,
    poles: [],
    search: '',
    sort: 'profile.lastName',
    sortDir: 'asc',
    startDate: startDate.toISOString(),
  },
};

// eslint-disable-next-line default-param-last
const usersReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  switch (action.type) {
    case GET_ALL_USERS:
      updatedState = {
        ...state,
        usersList: action.payload,
        user: null,
        error: null,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case CREATE_USER:
    case UPDATE_USER:
    case GET_USER:
      updatedState = {
        ...state,
        user: action.payload,
        error: null,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case USER_LOADING:
      updatedState = {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case SET_USERS_FILTERS:
      updatedState = { ...state, filters: action.payload };
      break;
    case USER_ERROR:
      updatedState = { ...state, error: action.payload };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default usersReducer;
