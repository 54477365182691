import React, { useState } from 'react';
import { TiArrowSortedDown } from 'react-icons/ti';
import styles from './DropDown.module.scss';

export default function DropDown({
  isMulti = false,
  selected,
  onChange,
  multiLabel = null,
  options = [],
}:{
  selected?: any,
  multiLabel?: string | null,
  isMulti?: boolean,
  onChange: any,
  options: any
}) {
  const [isOpen, setIsOpen] = useState(false);

  function handleChange(val : any) {
    if (!isMulti) {
      return onChange(val);
    }
    if (isMulti && !val) {
      return onChange([]);
    }
    if (isMulti && selected && selected?.find((v: string) => v === val)) {
      return onChange(selected.filter((v : string) => v !== val));
    }
    return onChange([...selected, val]);
  }

  return (
    <div
      className={styles.container}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className={styles.value}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>
          {!isMulti && options.find((o: any) => o.value === selected)?.label}
          {isMulti && multiLabel && `${selected.length > 0 ? `${selected.length} ` : ''}${multiLabel}`}
        </p>
        <button
          type='button'
          className={isOpen ? styles.active : ''}
          onClick={() => setIsOpen(!isOpen)}
        >
          <TiArrowSortedDown />
        </button>
      </div>
      <div
        className={styles.options}
        style={{ maxHeight: isOpen ? 300 : 0, outline: isOpen ? '1px solid #F1F0F3' : 'none', overflow: isOpen ? 'auto' : 'hidden' }}
      >
        {options.map((opt : any) => <button key={opt.value}
          onClick={() => handleChange(opt.value)}
          className={`${opt.value === selected ? styles.active : ''} ${isMulti && selected?.find((s: any) => opt.value === s) ? styles.active : ''}`}
        >
          {opt.color && <div className={styles.puce} style={{ backgroundColor: opt.color }}/>}
            <p>{opt.label}</p>
          </button>)}
      </div>
    </div>
  );
}
