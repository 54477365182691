import { Dispatch } from 'redux';
import {
  getData, putData, postData, deleteData,
} from '.';
import {
  GET_ALL_STRUCTURE,
  GET_STRUCTURE,
  STRUCTURE_ERROR,
  STRUCTURE_LOADING,
  UPDATE_STRUCTURE,
  SET_TOAST,
  CREATE_STRUCTURE,
  GET_STRUCTURE_POOLS,
  CREATE_POOL,
  UPDATE_POOL,
} from './types';

export const getAllStructuresAction = async (dispatch: Dispatch) => {
  const url = '/structure';
  dispatch({
    type: STRUCTURE_LOADING,
  });
  const response = await getData(STRUCTURE_ERROR, url, dispatch, true);
  if (response.data?.structures) {
    dispatch({
      type: GET_ALL_STRUCTURE,
      payload: response.data.structures,
    });
  }
};

export const getStructureAction = async (dispatch: Dispatch, id: string) => {
  const url = `/structure/${id}`;
  dispatch({
    type: STRUCTURE_LOADING,
  });
  const response = await getData(STRUCTURE_ERROR, url, dispatch, true);
  if (response.data.structure) {
    dispatch({
      type: GET_STRUCTURE,
      payload: response.data.structure,
    });
  }
};

export const getStructurePoolsAction = async (dispatch: Dispatch, id: string) => {
  const url = `/pool/${id}`;
  dispatch({
    type: STRUCTURE_LOADING,
  });
  const response = await getData(STRUCTURE_ERROR, url, dispatch, true);
  if (response.data.pools) {
    dispatch({
      type: GET_STRUCTURE_POOLS,
      payload: response.data.pools,
    });
  }
};

export const updateStructureAction = async (dispatch :Dispatch, data: any, id: string) => {
  const url = `/structure/${id}`;
  dispatch({
    type: STRUCTURE_LOADING,
  });
  const response = await putData(STRUCTURE_ERROR, url, dispatch, data, true);

  if (response.data?.structure) {
    dispatch({
      type: UPDATE_STRUCTURE,
      payload: response.data?.structure,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'Le site a été modifié',
        type: 'success',
      },
    });
  }
  return response;
};

export const createStructureAction = async (dispatch :Dispatch, data: any) => {
  const url = '/structure';
  dispatch({
    type: STRUCTURE_LOADING,
  });
  const response = await postData(STRUCTURE_ERROR, url, dispatch, data, true);

  if (response?.data?.structure) {
    dispatch({
      type: CREATE_STRUCTURE,
      payload: response.data?.structure,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'Le site a été créé',
        type: 'success',
      },
    });
  }
  return response;
};

export const deleteStructureAction = async (dispatch :Dispatch, id: string) => {
  const url = `/structure/${id}`;
  const response: any = await deleteData(STRUCTURE_ERROR, url, dispatch, true);
  console.log(response);
  if (response.data) {
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'Le site a été supprimé',
        type: 'success',
      },
    });
    dispatch({
      type: GET_STRUCTURE,
      payload: null,
    });
  }
  return response;
};

export const createPoolAction = async (dispatch :Dispatch, data: any) => {
  const url = '/pool';
  dispatch({
    type: STRUCTURE_LOADING,
  });
  const response = await postData(STRUCTURE_ERROR, url, dispatch, data, true);

  if (response?.data?.pool) {
    dispatch({
      type: CREATE_POOL,
      payload: response.data?.pool,
    });
  }
  return response;
};

export const updatePoolAction = async (dispatch :Dispatch, id: string, data: any) => {
  const url = `/pool/${id}`;
  dispatch({
    type: STRUCTURE_LOADING,
  });
  const response = await putData(STRUCTURE_ERROR, url, dispatch, data, true);

  if (response.data?.pool) {
    dispatch({
      type: UPDATE_POOL,
      payload: response.data?.pool,
    });
  }
  return response;
};
