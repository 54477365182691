/* eslint-disable no-restricted-syntax */
import {
  POST_NOTES_FILTERED,
  POST_NOTE,
  NOTE_LOADING,
  NOTE_ERROR,
  DELETE_NOTE,
  PUT_NOTE,
  GET_NOTE,
} from '../actions/types';
import { ActionReducer } from '../types';
import { INote } from '../types/note';

interface NoteState {
  notes: INote[],
  note: INote | null
  isLoading: string[],
  error: string | null,
}

const DEFAULT_STATE: NoteState = {
  notes: [],
  note: null,
  isLoading: [],
  error: null,
};

// eslint-disable-next-line default-param-last
const notesReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  const { notes } = state;
  const noteIndex = notes?.findIndex((c) => c._id === action.payload?._id);
  switch (action.type) {
    case POST_NOTES_FILTERED:
      updatedState = {
        ...state,
        notes: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case GET_NOTE:
      updatedState = {
        ...state,
        note: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case POST_NOTE:
      updatedState = {
        ...state,
        note: action.payload,
        notes: [action.payload, ...notes],
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case PUT_NOTE:
      if (noteIndex !== -1) {
        notes[noteIndex] = action.payload;
      }
      updatedState = {
        ...state,
        notes,
        note: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case DELETE_NOTE:
      updatedState = {
        ...state,
        notes: notes.filter((d) => d._id !== action.payload),
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case NOTE_LOADING:
      updatedState = {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case NOTE_ERROR:
      updatedState = { ...state, error: action.payload || null };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default notesReducer;
