import React from 'react';
import styles from './tooltip.module.scss';

const Tooltip = ({ children, message }: { children: React.ReactNode, message: string }) => (
    <div className={styles.tooltip}>
      <>{children}</>
      <div className={styles.message}>
        {message}
      </div>
    </div>
);

export default Tooltip;
