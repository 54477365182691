import React from 'react';
import styles from './Search.module.scss';

export default function Search({
  value = '',
  handleChange,
}:{
  value?: string,
  handleChange: any,
}) {
  return (
    <div className={styles.search}>
      <input
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        placeholder='Rechercher...'
      />
    </div>
  );
}
