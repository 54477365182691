import React, { ReactNode } from 'react';
import { NavAdmin, NavMember } from '../Nav/Nav';
import styles from './layout.module.scss';

interface LayoutProps {
  children: ReactNode;
  role: string;
}

const Layout: React.FC<LayoutProps> = ({ children, role }) => (
  <div className={styles.layout}>
    <div className={styles.container}>
      {children}
    </div>
    {role === 'Member' ? <NavMember/> : <NavAdmin />}
  </div>
);

export default Layout;
