import React, { useEffect, useState } from 'react';
import { FaSortDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { SET_USERS_FILTERS } from '../../actions/types';
import styles from './SelectDate.module.scss';

type DayType = {
  date: string,
  day: number,
  weekDay: string,
};

const SelectDate = () => {
  const [days, setDays] = useState<DayType[]>([]);
  const dispatch = useDispatch();
  const { filters } = useSelector((store: any) => store.usersReducer);
  const date = new Date(filters.startDate);
  const dayOfWeek = new Date(filters.startDate).getDay();
  const diff = (dayOfWeek === 1) ? 0 : dayOfWeek - 1;
  const monday = diff === -1
    ? new Date(new Date(date).setDate(date.getDate() - 6))
    : new Date(new Date(date).setDate(date.getDate() - diff));
  const previousMonday = new Date(new Date(date).setDate(monday.getDate() - 7));
  const nextMonday = new Date(new Date(date).setDate(monday.getDate() + 7));

  function handleChangeFilters(obj: any) {
    dispatch({
      type: SET_USERS_FILTERS,
      payload: {
        ...filters,
        ...obj,
      },
    });
  }

  useEffect(() => {
    if (filters.startDate) {
      const daysOptions : DayType[] = [];
      let current = 0;
      const end = 6;
      while (current <= end) {
        const newDate = new Date(new Date(monday)
          .setDate(new Date(monday).getDate() + current));
        daysOptions.push({
          date: newDate.toISOString(),
          day: newDate.getDate(),
          weekDay: new Intl.DateTimeFormat('fr', { weekday: 'short' }).format(newDate),
        });
        current += 1;
      }
      setDays(daysOptions);
    }
  }, [filters.startDate]);

  return (<div className={styles.container}>
    <button
      className={styles.monday}
      onClick={() => handleChangeFilters({
        startDate: previousMonday.toISOString(),
      })}
    >
      <FaSortDown />
    </button>
    {days.map((d :DayType) => (<button
      key={d.date}
      className={styles.day}
      onClick={() => handleChangeFilters({
        startDate: d.date,
      })}
    >
        <p>{d.weekDay}</p>
        <p className={d.date === filters.startDate ? styles.active : ''}>{d.day}</p>
      </button>))}
      <button
          className={`${styles.monday}`}
          onClick={() => handleChangeFilters({
            startDate: nextMonday.toISOString(),
          })}
        >
        <FaSortDown />
      </button>
    </div>
  );
};

export default SelectDate;
