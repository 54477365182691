import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FaCalendarCheck,
  FaRegStar, FaSortDown, FaStar,
} from 'react-icons/fa';
import { IoFilter } from 'react-icons/io5';
import { BsEmojiSunglassesFill } from 'react-icons/bs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'date-fns';

import {
  deleteFavoriteWeekAction,
  getShiftsListAction,
  getWeekOptionsAction,
  postFavoriteWeekAction,
  postPublishWeekAction,
  postShiftsFilteredAction,
} from '../../actions/shift';
import { getAllStructuresAction, getStructureAction, getStructurePoolsAction } from '../../actions/structures';
import {
  GET_NOTE, SET_PLANNING_VIEW, SET_USERS_FILTERS, SET_WEEK_VIEW,
} from '../../actions/types';

import { postUsersFilteredAction } from '../../actions/users';
import DropDown from '../../components/DropDown/DropDown';

import Header from '../../components/Header';
import PlanningRow from '../../components/PlanningRow';
import { DayType, FavioriteWeek, IShift } from '../../types/shift';

import {
  ILine, IPole, IPool, IStructure,
} from '../../types/structure';
import { IUser } from '../../types/user';

import calendar from '../../assets/images/calendar.svg';
import useWindowSize from '../../hooks/useWindowSize';
import SelectDate from '../../components/SelectDate/SelectDate';
import FiltersMobile from '../../components/FiltersMobile/FiltersMobile';

import styles from './AdminPlanning.module.scss';
import ModalStandalone, { IModalHandle } from '../../lib/ModalStandalone';
import ImportModal from '../../components/ImportModal/ImportModal';
import ExportButton from '../../components/ExportButton';
import DayPicker from '../../components/DayPicker';
import { postContractsFilteredAction } from '../../actions/contract';
import { createHolidayAction, deleteHolidayAction, getHolidaysAction } from '../../actions/holiday';
import { IHoliday } from '../../types/holiday';
import { postNotesFilteredAction } from '../../actions/note';
import NoteModal from '../../components/NoteModal';
import { getActiveContractsBetweenDates, getWeekNumber } from '../../utils';
import { IContract } from '../../types/contract';
import Tooltip from '../../components/Toolip';

const AdminPlanning = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const isMobile = width && width < 1024;

  const { user } = useSelector((store: any) => store.authReducer);
  const { usersList, filters } = useSelector((store: any) => store.usersReducer);
  const { note } = useSelector((store: any) => store.notesReducer);
  const { holidays } = useSelector((store: any) => store.holidayReducer);
  const { structuresList, pools } = useSelector((store: any) => store.structureReducer);
  const { contracts } = useSelector((store: any) => store.contractsReducer);

  const {
    shiftsList, list, isWeekView, planningView, favioritesWeeks,
  } = useSelector((store: any) => store.shiftsReducer);

  const modalRef = useRef<IModalHandle | any>(null);
  const noteModalRef = useRef<IModalHandle | any>(null);

  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const [week, setWeek] = useState<DayType[]>([]);

  const date = new Date(filters.startDate);
  const dayOfWeek = new Date(date).getDay();
  const diff = (dayOfWeek === 1) ? 0 : dayOfWeek - 1;
  const monday = diff === -1
    ? new Date(new Date(date).setDate(date.getDate() - 6))
    : new Date(new Date(date).setDate(date.getDate() - diff));
  const sunday = new Date(new Date(monday).setDate(monday.getDate() + 6));
  const today = new Date();
  const isImport = today.getTime() < new Date(sunday).getTime();
  const isPublished = shiftsList?.filter((s: IShift) => s.isPublished === true).length > 0;

  const structure = structuresList?.find((s: IStructure) => s._id === filters.structure);
  const polesList = structuresList?.find((s: IStructure) => s._id === filters.structure)?.poles;
  const poles = polesList?.filter((po: IPole) => {
    if (user?.role === 'Manager') {
      if (user?.poles.find((p: string) => p === po._id)) {
        return po;
      }
      return null;
    }
    return po;
  });

  const dateFormated = filters?.startDate && new Intl.DateTimeFormat('fr', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(filters?.startDate));

  const mondayFormated = filters?.startDate && new Intl.DateTimeFormat('fr', {
    day: 'numeric',
    month: 'long',
  }).format(new Date(filters?.startDate));

  const sundayFormated = filters?.endDate && new Intl.DateTimeFormat('fr', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(filters?.endDate));

  const weekNumber = getWeekNumber(monday);

  const weekFormated = `${mondayFormated} > ${sundayFormated}`;
  const savedStructure = localStorage.getItem('structure');

  const isFavorite = favioritesWeeks?.find((w: FavioriteWeek) => w.label?.includes(weekFormated));
  const isHoliday = !filters?.endDate && holidays?.find(
    (h: IHoliday) => h.date === filters?.startDate,
  );

  const activesContracts = getActiveContractsBetweenDates(
    contracts || [],
    filters.startDate,
    filters?.endDate,
  );

  const usersSorted = useMemo(() => {
    const users : IUser[] = [];
    if (!poles || !usersList) {
      return users;
    }
    poles.forEach((po: IPole) => {
      usersList.forEach((u:IUser) => {
        const userFound = users.find((ur: IUser) => ur._id === u._id);
        const poleFound = u.poles.find((s: string) => s === po._id);
        if (!userFound && poleFound) {
          users.push(u);
        }
      });
    });
    return users;
  }, [usersList, poles]);

  function handleChangeFilters(obj: any) {
    if (obj?.structure) {
      localStorage.setItem('structure', obj.structure);
    }
    dispatch({
      type: SET_USERS_FILTERS,
      payload: {
        ...filters,
        ...obj,
      },
    });
  }

  function handleStartDate(dir: string) {
    if (!filters?.startDate) {
      return null;
    }
    const startDate = new Date(`${format(new Date(filters.startDate), 'yyyy-MM-dd')}T00:00:00.000Z`);
    const endDate = filters?.endDate ? new Date(`${format(new Date(filters.endDate), 'yyyy-MM-dd')}T00:00:00.000Z`) : null;
    if (endDate) {
      if (dir === 'prev') {
        startDate.setDate(startDate.getDate() - 7);
        endDate.setDate(endDate.getDate() - 7);
      } else {
        startDate.setDate(startDate.getDate() + 7);
        endDate.setDate(endDate.getDate() + 7);
      }
    } else if (dir === 'prev') {
      startDate.setDate(startDate.getDate() - 1);
    } else {
      startDate.setDate(startDate.getDate() + 1);
    }
    return handleChangeFilters({
      startDate: startDate.toISOString(),
      endDate: endDate ? endDate.toISOString() : null,
    });
  }

  async function handleFavorite() {
    if (!isFavorite?._id) {
      await postFavoriteWeekAction(dispatch, {
        structure: filters.structure,
        startDate: new Date(`${format(filters.startDate, 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString(),
      });
    } else {
      await deleteFavoriteWeekAction(dispatch, isFavorite?._id);
    }

    return getWeekOptionsAction(dispatch, filters.structure);
  }

  function handleHoliday() {
    if (user?.role === 'Admin') {
      if (!isHoliday?._id) {
        createHolidayAction(dispatch, {
          date: new Date(`${format(filters.startDate, 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString(),
        });
      } else {
        deleteHolidayAction(dispatch, isHoliday?._id);
      }
    }

    return null;
  }

  async function navigateToPreview() {
    const currentUrl = window.location.origin;
    const token = localStorage.getItem('token');
    const startDate = filters?.startDate && new Date(`${format(new Date(filters.startDate), 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString();
    const endDate = filters?.endDate && new Date(`${format(new Date(filters.endDate), 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString();
    const query : any = {
      endDate,
      startDate,
      structure: filters.structure,
      planningView,
    };
    if (!endDate) {
      delete query.endDate;
    }
    if (filters?.poles?.length > 0) {
      query.poles = filters.poles;
    }
    const searchQuery = `?${new URLSearchParams(query)}`;
    const url = `${currentUrl}/export-preview/${token?.replace('JWT ', '')}/${searchQuery}`;
    window.open(url, '_blank');
    return true;
  }

  function handleChangePlanning(type: string) {
    dispatch({
      type: SET_PLANNING_VIEW,
      payload: type,
    });
  }

  async function handleChangePublishWeekStatus() {
    const data = {
      structure: filters.structure,
      startDate: new Date(`${format(filters.startDate, 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString(),
      endDate: new Date(`${format(filters.endDate, 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString(),
      isPublished: !isPublished,
    };
    await postPublishWeekAction(dispatch, data);
  }

  useEffect(() => {
    getAllStructuresAction(dispatch);
    getHolidaysAction(dispatch);
  }, []);

  useEffect(() => {
    if (filters.structure) {
      postUsersFilteredAction(dispatch, { ...filters, search: '' });
      postShiftsFilteredAction(dispatch, filters);
      postContractsFilteredAction(dispatch, filters);
      postNotesFilteredAction(dispatch, filters);
    }
  }, [filters]);

  useEffect(() => {
    if (filters?.structure) {
      getStructurePoolsAction(dispatch, filters.structure);
      getStructureAction(dispatch, filters.structure);
      getWeekOptionsAction(dispatch, filters.structure);
      getShiftsListAction(dispatch, filters.structure);
    }
  }, [filters?.structure]);

  useEffect(() => {
    if (!filters.structure) {
      if (user?.structure?._id) {
        handleChangeFilters({
          structure: user.structure._id,
        });
      } else if (savedStructure) {
        handleChangeFilters({
          structure: savedStructure,
        });
      } else if (structuresList.length && user?.role === 'Admin') {
        handleChangeFilters({
          structure: structuresList[0]._id,
        });
      }
    }
  }, [user, structuresList]);

  useEffect(() => {
    if (isWeekView) {
      handleChangeFilters({
        startDate: monday.toISOString(),
        endDate: sunday.toISOString(),
      });
    } else {
      handleChangeFilters({
        endDate: null,
      });
    }
  }, [isWeekView]);

  useEffect(() => {
    if (isMobile) {
      dispatch({
        type: SET_WEEK_VIEW,
        payload: false,
      });
    }
  }, [isMobile]);

  useEffect(() => {
    if (!filters?.endDate) {
      dispatch({
        type: SET_WEEK_VIEW,
        payload: false,
      });
    }
  }, [filters?.endDate]);

  useEffect(() => {
    if (isWeekView && filters.endDate) {
      const daysOptions : DayType[] = [];
      let current = 0;
      const end = 6;
      while (current <= end) {
        const newDate = new Date(new Date(monday)
          .setDate(new Date(monday).getDate() + current));
        daysOptions.push({
          date: new Date(`${format(newDate, 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString(),
          day: newDate.getDate(),
          weekDay: new Intl.DateTimeFormat('fr', { weekday: 'long', day: 'numeric' }).format(newDate),
        });
        current += 1;
      }
      setWeek(daysOptions);
    } else {
      setWeek([]);
    }
  }, [isWeekView, filters.endDate]);

  useEffect(() => {
    if (note) {
      noteModalRef.current.open();
    } else {
      noteModalRef.current.close();
    }
  }, [note]);

  return (
    <div className={styles.container}>
      <Header name='Planning' isProfile />
      <div className={styles.content}>
        <div className={styles.nav}>
          <button
            type='button'
            onClick={() => handleChangePlanning('pools')}
            className={planningView === 'pools' ? styles.active : ''}
          >
            Équipements
            <div className={styles.bar}/>
          </button>
          <button
            type='button'
            onClick={() => handleChangePlanning('users')}
            className={planningView === 'users' ? styles.active : ''}
          >
            Membres
            <div className={styles.bar}/>
          </button>
          <button
            type='button'
            onClick={() => handleChangePlanning('global')}
            className={planningView === 'global' ? styles.active : ''}
          >
            Global
            <div className={styles.bar}/>
          </button>
        </div>
        <div className={styles.filters}>
            {!isMobile && <div className={styles.row}>
              <div className={styles['select-date']}>
                <button
                  type='button'
                  onClick={() => handleStartDate('prev')}
                >
                  <FaSortDown />
                </button>
                <div className={styles.dates}>
                  <h4>
                    {isWeekView ? `${mondayFormated} > ${sundayFormated}` : dateFormated}
                    {!isWeekView
                    && <Tooltip
                      message={!isHoliday?._id ? 'Ajouter le jour férié' : 'Supprimer le jour férier'}
                    >
                      <span
                      className={`${styles.holiday} ${!isHoliday?._id && user?.role !== 'Admin' ? styles.none : ''} ${isHoliday?._id ? styles.active : ''}`}
                      onClick={() => handleHoliday()}
                    >
                      <BsEmojiSunglassesFill />
                    </span>
                    </Tooltip>
                  }
                    <span><br />Semaine {weekNumber}
                      {isWeekView && shiftsList.length > 0
                      && <Tooltip
                        message={!isFavorite?._id ? 'Ajouter la semaine aux imports' : 'Supprimer la semaine des imports'}
                      ><span
                          className={styles.favorite}
                          onClick={() => handleFavorite()}
                        >
                          {isFavorite?._id ? <FaStar /> : <FaRegStar />}
                        </span>
                      </Tooltip>
                    }
                    </span>
                  </h4>
                </div>
                 <button
                    type='button'
                    onClick={() => handleStartDate('next')}
                >
                  <FaSortDown />
                </button>
              </div>
              {!isMobile && filters?.startDate && <DayPicker
                handleChangeFilters={handleChangeFilters}
              />}
              <div className={`${styles.col}`}>
                <DropDown
                  onChange={(value: any) => dispatch({
                    type: SET_WEEK_VIEW,
                    payload: value,
                  })}
                  selected={isWeekView}
                  options={[
                    { label: 'Vue jour', value: false },
                    { label: 'Vue semaine', value: true },
                  ]}
                />
              </div>
            </div>
            }
          <div className={styles.row}>
            <div className={styles.col}>
              {(user?.role === 'Admin' || user?.role === 'Owner') && structuresList
                && <DropDown
                  onChange={(value: any) => handleChangeFilters({ structure: value, poles: [] })}
                  selected={filters?.structure}
                  options={[
                    ...structuresList.map((s : IStructure) => ({ label: s.name, value: s._id })),
                  ]}
                />
              }
            </div>
            {poles && !isMobile
              && <div className={styles.col}>
                <DropDown
                onChange={(value: any) => handleChangeFilters({ poles: value })}
                selected={filters?.poles}
                multiLabel={filters?.poles.length > 0 ? 'catégories séléctionnées' : 'Catégories'}
                isMulti
                options={[
                  { label: 'Toutes les catégories', value: null },
                  ...poles.map((s : IPole) => ({
                    label: s.name,
                    value: s._id,
                    color: s.color,
                  })),
                ]}
              />
              </div>
            }
            {!isMobile
              && isImport
              && isWeekView
              && (user?.role === 'Admin' || user?.role === 'Manager')
              && <div className={styles.action}>
              <Tooltip
                message={'Importer une semaine'}
              ><button
                type='button'
                className={styles.import}
                onClick={() => modalRef.current.open()}
              >
              <img src={calendar} alt='import-week' />
            </button>
            </Tooltip>
            </div>
            }
            {!isMobile && shiftsList.length > 0
              && <div className={styles.action}>
                <Tooltip
                message={'Voir l\'export'}
              >
                <ExportButton
                  callBack={() => navigateToPreview()}
                />
              </Tooltip>
            </div>}
            {!isMobile && shiftsList.length > 0 && isWeekView
              && <div className={styles.action}>
              <Tooltip
                message={!isPublished ? 'Publier la semaine' : 'Supprimer la publication'}
              ><button
                    type='button'
                    className={`${styles.publish} ${isPublished ? styles.active : ''}`}
                    onClick={() => handleChangePublishWeekStatus()}
                  >
                  <FaCalendarCheck />
                </button>
              </Tooltip>
              </div>
            }
          </div>
          {isMobile
            && <div className={styles.row}>
              <div className={styles['select-date-mobile']}>
                <SelectDate
                />
              </div>
            </div>
          }
        </div>
        <div className={styles.labels}>
          <div className={styles.row}>

          </div>
          <div className={styles.row}>
            {!filters.endDate && list?.shifts?.map((s : any) => {
              const label = s.label?.includes(':00') && s.label !== '02:00' && s.label !== structure?.schedules?.end ? s.label : null;
              return (
              <div key={s.label}
                style={{ width: `${100 / list.shifts.length}%` }}
                className={styles.hour}
              >
                <p>{label}</p>
              </div>
              );
            })}
            {filters.endDate && week?.map((s : any) => (
              <div key={s.date}
                style={{ width: `${100 / 7}%` }}
                className={styles.hour}
              >
                <p>{s.weekDay}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles['mobile-filters']}>
          <h4>{dateFormated}</h4>
          {poles
          && <button
            onClick={() => setFiltersIsOpen(!filtersIsOpen)}
          >
            <IoFilter />
            {filters.poles.length > 0
              && <div className={styles.count}>
                <p>{filters.poles.length}</p>
              </div>
            }
          </button>
          }
        </div>
        <div className={styles.list}>
          <div className={styles.pools}>
            {planningView !== 'users' && poles && filters.structure && shiftsList
            && pools?.map((p : IPool) => (
              <div key={p._id} className={styles.grid}>
                {p.lines.map((line: ILine, index: number) => <PlanningRow
                  key={line._id}
                  line={line}
                  lines={p.lines}
                  pool={p}
                  name={index === 0 ? p.name : null}
                  isMobile={!!isMobile}
                  week={week}
                  shifts={shiftsList
                    .filter((s: IShift) => s.line === line._id)}
                />)}
              </div>
            ))}
          </div>
          <div className={styles.grid}>
            {planningView !== 'pools' && poles && filters.structure && shiftsList && usersList
            && usersSorted?.filter((u: IUser) => u.isActive)
              .filter((u: IUser) => {
                if (!activesContracts.find((a: IContract) => a.user === u._id)) {
                  return null;
                }
                if (user.role === 'Manager') {
                  if (u.poles.find((p) => user.poles.find((pu: string) => p === pu))) {
                    return u;
                  }
                  return null;
                }
                return u;
              })
              .map((u: IUser) => <PlanningRow
              key={u._id}
              user={u}
              isMobile={!!isMobile}
              week={week}
              shifts={shiftsList
                .filter((s: IShift) => s.user === u._id)}
            />)}
          </div>
        </div>
      </div>
      <FiltersMobile
        poles={poles}
        filters={filters}
        isOpen={filtersIsOpen}
        setIsOpen={setFiltersIsOpen}
      />
      <ModalStandalone ref={modalRef} isDraggable>
        <ImportModal
          close={() => modalRef?.current?.close()}
        />
      </ModalStandalone>
      <ModalStandalone
        ref={noteModalRef}
        closeCallBack={() => {
          dispatch({
            type: GET_NOTE,
            payload: null,
          });
        }}
      >
        <NoteModal />
      </ModalStandalone>
    </div>
  );
};

export default AdminPlanning;
