import React, { useEffect, useState } from 'react';
import { ImArrowLeft2 } from 'react-icons/im';
import { AiTwotoneWarning } from 'react-icons/ai';
import { BsCheck } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { IShift } from '../../types/shift';
import TimeLine from '../TimeLineVertical/TimeLineVertical';
import styles from './ValidationPanel.module.scss';
import { ErrorField } from '../../lib/HooksFormFields';
import { postShiftValidationAction } from '../../actions/shift';
import { createDatesNotifsAction } from '../../actions/notif';

const SelectDay = ({
  week,
  setCurrentDay,
  setSelectedDays,
  selected,
}:{
  week: Date[],
  selected: Date[],
  setSelectedDays?: any,
  setCurrentDay: (val: Date) => void,
}) => {
  const daysOptions = week.map((d) => ({
    date: d,
    day: d.getDate(),
    weekDay: new Intl.DateTimeFormat('fr', { weekday: 'short' }).format(d),
  }));

  function handleChangeDay(date: Date) {
    if (!setSelectedDays) {
      return setCurrentDay(date);
    }
    if (selected.find((s) => date.toISOString() === s.toISOString())) {
      return setSelectedDays(selected.filter((s) => date.toISOString() !== s.toISOString()));
    }
    return setSelectedDays([...selected, date]);
  }

  return (<div className={styles.days}>
      {daysOptions.map((d) => <button
      key={d.date.toISOString()}
      className={styles.day}
      onClick={() => handleChangeDay(d.date)}
    >
        <p>{d.weekDay}</p>
        <p className={selected.find((s: Date) => s === d.date) ? styles.active : ''}>{d.day}</p>
      </button>)}
    </div>);
};

export default function ValidationPanel({
  isOpen,
  close,
  prevWeek,
  shifts,
}:{
  isOpen: boolean,
  close: () => void
  prevWeek: Date[],
  shifts: IShift[]
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((store: any) => store.authReducer);
  const [isWeek, setIsWeek] = useState<boolean>(false);
  const [currentDay, setCurrentDay] = useState<Date | null>(prevWeek[0]);
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);
  const [error, setErrors] = useState<any>({});
  const [message, setMessage] = useState('');

  const dateFormated = currentDay && new Intl.DateTimeFormat('fr', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(currentDay));

  async function handleSendNotifs() {
    const errors : any = {};
    if (selectedDays.length === 0) {
      errors.days = 'Les jours sont requis';
    }
    if (!message) {
      errors.message = 'Le message est requis';
    }
    if (errors.message || errors.days) {
      return setErrors(errors);
    }
    setErrors(errors);
    const dates = selectedDays.map((s) => ({
      date: s.toISOString(),
      message,
      structure: user.structure,
    }));
    await createDatesNotifsAction(dispatch, { dates });
    return close();
  }

  async function handleValidWeek() {
    const week = prevWeek.map((s) => s.toISOString());
    await postShiftValidationAction(dispatch, {
      structure: user.structure?._id,
      startDate: week[0],
      endDate: week[week.length - 1],
    });
    return close();
  }

  useEffect(() => {
    if (!isOpen && prevWeek.length > 0) {
      setSelectedDays([]);
      setMessage('');
      setIsWeek(true);
      setCurrentDay(prevWeek[0]);
    }
  }, [isOpen, prevWeek]);

  return (
    <div className={`${styles.container} ${isOpen ? styles.active : ''}`}>
      <div className={styles.header}>
        {isWeek
          ? <button
            type='button'
            onClick={() => close()}
          >
            <ImArrowLeft2 />
          <p>Validation</p>
          </button>
          : <button
            type='button'
            onClick={() => setIsWeek(true)}
          >
            <ImArrowLeft2 />
            <p>Rectification</p>
          </button>
        }
      </div>
      {isWeek
        ? <>
        <SelectDay
          week={prevWeek}
          setCurrentDay={setCurrentDay}
          selected={isWeek && currentDay ? [currentDay] : []}
        />
        <div className={styles.week}>
          {dateFormated && <h4>{dateFormated}</h4>}
          <div className={styles.timeline}>
            <TimeLine
              shifts={shifts.filter((s) => s.date === currentDay?.toISOString())}
            />
          </div>
        </div>
        <div className={styles.actions}>
          <button
            type="button"
            onClick={() => handleValidWeek()}
            className={styles.btn}
          >
            <div className={styles.icon}>
              <BsCheck />
            </div>
            <p>Valider ma semaine</p>
          </button>
          <button
            type="button"
            onClick={() => {
              setIsWeek(false);
              if (currentDay) {
                setSelectedDays([currentDay]);
              }
            }}
            className={`${styles.btn}`}
          >
            <div
              className={`${styles.icon} ${styles.danger}`}
            >
              <AiTwotoneWarning />
            </div>
          </button>
        </div>
       </>
        : <div className={styles.form}>
            <div className={styles.field}>
              <label>Séléctionnez les jours concernés</label>
              <SelectDay
                week={prevWeek}
                setCurrentDay={setCurrentDay}
                selected={selectedDays}
                setSelectedDays={setSelectedDays}
              />
              {error.days && <ErrorField message={error.days} />}
            </div>
            <div className={styles.field}>
              <label>Détails de la rectification</label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              {error.message && <ErrorField message={error.message} />}
            </div>
          <div className={styles.actions}>
          <button
            type="button"
            onClick={() => handleSendNotifs()}
            className={styles.btn}
          >
            <div className={styles.icon}>
              <BsCheck />
            </div>
            <p>Envoyer la demande</p>
            </button>
          </div>
        </div>
      }
    </div>
  );
}
