import React, { useState, useEffect } from 'react';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FaPen } from 'react-icons/fa';
import { BsCheck } from 'react-icons/bs';
import { updateProfile } from '../../actions/auth';

import Header from '../../components/Header';
import UserForm from '../../components/User/UserForm';
import UserInfos from '../../components/User/UserInfos';

import styles from './Profile.module.scss';

const Profile = () => {
  const [isEdit, setIsEdit] = useState(false);

  const { user } = useSelector((store: any) => store.authReducer);
  const dispatch = useDispatch();
  const methods = useForm();
  const onSubmit = (data : FieldValues) => {
    updateProfile(dispatch, data);
  };

  useEffect(() => {
    methods.reset({ ...user });
  }, [user]);

  return (
    <div className={styles.container}>
      <Header name='Mon profil'/>
      {!isEdit
        ? <button className={styles.edit} onClick={() => setIsEdit(true)}>
          <div className={styles.icon}>
            <FaPen />
          </div>
          <span>MODIFIER MES INFOS</span>
        </button>
        : <button className={styles.edit} onClick={methods.handleSubmit(onSubmit)}>
          <div className={styles.icon}>
            <BsCheck size={28}/>
          </div>
          <span>Valider les modifications</span>
        </button>
      }
      <div className={styles.content}>
      <FormProvider {...methods} >
        {isEdit
          ? <UserForm
            isProfile
            onSubmit={(obj: any) => updateProfile(dispatch, obj)}
          />
          : <UserInfos
            isProfile
            onSubmit={(obj: any) => updateProfile(dispatch, obj)}
          />}
        </FormProvider>
      </div>
    </div>
  );
};

export default Profile;
