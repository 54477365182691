// ACTIONS AUTH
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_PUT_PROFILE = 'AUTH_PUT_PROFILE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_TOAST = 'SET_TOAST';

// STRUCTURES ACTIONS
export const STRUCTURE_LOADING = 'STRUCTURE_LOADING';
export const STRUCTURE_ERROR = 'STRUCTURE_ERROR';
export const GET_STRUCTURE = 'GET_STRUCTURE';
export const GET_STRUCTURE_POOLS = 'GET_STRUCTURE_POOLS';
export const GET_ALL_STRUCTURE = 'GET_ALL_STRUCTURE';
export const CREATE_STRUCTURE = 'CREATE_STRUCTURE';
export const UPDATE_STRUCTURE = 'UPDATE_STRUCTURE';
export const CREATE_POOL = 'CREATE_POOL';
export const UPDATE_POOL = 'UPDATE_POOL';

// USERS ACTIONS
export const USER_LOADING = 'USER_LOADING';
export const USER_ERROR = 'USER_ERROR';
export const GET_USER = 'GET_USER';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_USERS_FILTERS = 'SET_USERS_FILTERS';

// SHIFT ACTIONS
export const SHIFT_LOADING = 'SHIFT_LOADING';
export const SHIFT_ERROR = 'SHIFT_ERROR';
export const GET_ALL_SHIFTS = 'GET_ALL_SHIFTS';
export const SET_SHIFT_MODALE = 'SET_SHIFT_MODALE';
export const GET_SHIFTS_LIST = 'GET_SHIFTS_LIST';
export const POST_SHIFT = 'POST_SHIFT';
export const SET_WEEK_VIEW = 'SET_WEEK_VIEW';
export const SET_PLANNING_VIEW = 'SET_PLANNING_VIEW';
export const GET_MEMBER_HOME = 'GET_MEMBER_HOME';
export const POST_SHIFT_VALIDATION = 'POST_SHIFT_VALIDATION';
export const POST_SHIFT_ERROR = 'POST_SHIFT_ERROR';
export const POST_SHIFT_ALERT = 'POST_SHIFT_ALERT';

// WEEK ACTIONS
export const POST_WEEK = 'POST_WEEK';
export const POST_WEEKS_OPTIONS = 'POST_WEEKS_OPTIONS';
export const DELETE_WEEK = 'DELETE_WEEK';

// HOLIDAY ACTIONS
export const POST_HOLIDAY = 'POST_HOLIDAY';
export const GET_HOLIDAYS = 'GET_HOLIDAYS';
export const DELETE_HOLIDAY = 'DELETE_HOLIDAY';
export const HOLIDAY_LOADING = 'HOLIDAY_LOADING';
export const HOLIDAY_ERROR = 'HOLIDAY_ERROR';

// NOTE ACTIONS
export const POST_NOTE = 'POST_NOTE';
export const POST_NOTES_FILTERED = 'POST_NOTES_FILTERED';
export const PUT_NOTE = 'PUT_NOTE';
export const GET_NOTE = 'GET_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const NOTE_LOADING = 'NOTE_LOADING';
export const NOTE_ERROR = 'NOTE_ERROR';

// NOTIF ACTIONS
export const NOTIF_LOADING = 'NOTIF_LOADING';
export const NOTIF_ERROR = 'NOTIF_ERROR';
export const GET_ALL_NOTIFS = 'GET_ALL_NOTIFS';
export const CREATE_NOTIF = 'CREATE_NOTIF';
export const UPDATE_NOTIF = 'UPDATE_NOTIF';
export const SET_NOTIF_IS_OPEN = 'SET_NOTIF_IS_OPEN';
export const POST_DATES_NOTIF = 'POST_DATES_NOTIF';

// POST CONTRACT
export const GET_USER_CONTRACTS = 'GET_USER_CONTRACTS';
export const GET_STRUCTURE_CONTRACTS = 'GET_STRUCTURE_CONTRACTS';
export const GET_CONTRACT = 'GET_CONTRACT';
export const POST_CONTRACT = 'POST_CONTRACT';
export const PUT_CONTRACT = 'PUT_CONTRACT';
export const CONTRACT_LOADING = 'CONTRACT_LOADING';
export const CONTRACT_ERROR = 'CONTRACT_ERROR';
export const DELETE_CONTRACT = 'DELETE_CONTRACT';

export const FILE_ERROR = 'FILE_ERROR';

// EPXORT ACTIONS
export const GET_EXPORT_LIST = 'GET_EXPORT_LIST';
