import { Dispatch } from 'redux';
import {
  postData, deleteData, putData,
} from '.';
import {
  DELETE_NOTE,
  NOTE_LOADING,
  POST_NOTE,
  POST_NOTES_FILTERED,
  PUT_NOTE,
  SET_TOAST,
} from './types';

export const postNotesFilteredAction = async (dispatch :Dispatch, data: any) => {
  const url = '/note/filtered';
  dispatch({
    type: NOTE_LOADING,
    payload: POST_NOTES_FILTERED,
  });
  const response = await postData(NOTE_LOADING, url, dispatch, data, true);

  if (response?.data?.notes) {
    dispatch({
      type: POST_NOTES_FILTERED,
      payload: response.data?.notes,
    });
  }
  return response;
};

export const createNoteAction = async (dispatch :Dispatch, data: any) => {
  const url = '/note';
  dispatch({
    type: NOTE_LOADING,
    payload: POST_NOTE,
  });
  const response = await postData(NOTE_LOADING, url, dispatch, data, true);

  if (response?.data?.note) {
    dispatch({
      type: POST_NOTE,
      payload: response.data?.note,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'La note a été créé',
        type: 'success',
      },
    });
  }
  return response;
};

export const updateNoteAction = async (dispatch :Dispatch, data: any, id: string) => {
  const url = `/note/${id}`;
  dispatch({
    type: NOTE_LOADING,
    payload: PUT_NOTE,
  });
  const response = await putData(NOTE_LOADING, url, dispatch, data, true);

  if (response.data?.note) {
    dispatch({
      type: PUT_NOTE,
      payload: response.data?.note,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'La note a été modifiée',
        type: 'success',
      },
    });
  }
  return response;
};

export const deleteNoteAction = async (dispatch: Dispatch, id: string) => {
  const url = `/note/${id}`;
  const response: any = await deleteData(NOTE_LOADING, url, dispatch, true);
  if (response?.data) {
    dispatch({
      type: DELETE_NOTE,
      payload: id,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'La note a été supprimée',
        type: 'success',
      },
    });
  }
  return response;
};
