import { Dispatch } from 'redux';
import { getData, postData, putData } from '.';
import {
  GET_ALL_NOTIFS,
  SET_TOAST,
  NOTIF_ERROR,
  NOTIF_LOADING,
  UPDATE_NOTIF,
} from './types';

export const getNotifsAction = async (dispatch: Dispatch) => {
  const url = '/notif';
  dispatch({
    type: NOTIF_LOADING,
  });
  const response = await getData(NOTIF_ERROR, url, dispatch, true);
  if (response.data?.notifs) {
    dispatch({
      type: GET_ALL_NOTIFS,
      payload: response.data.notifs,
    });
  }
};

export const createDatesNotifsAction = async (dispatch :Dispatch, data: any) => {
  const url = '/notif/dates';
  dispatch({
    type: NOTIF_LOADING,
  });
  const response = await postData(NOTIF_ERROR, url, dispatch, data, true);

  if (response?.data?.message) {
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'la demande à été soumise',
        type: 'success',
      },
    });
  }
  return response;
};

export const updateNotifAction = async (dispatch :Dispatch, data: any, id: string) => {
  const url = `/notif/${id}`;
  dispatch({
    type: NOTIF_LOADING,
  });
  const response = await putData(NOTIF_ERROR, url, dispatch, data, true);

  if (response.data?.notif) {
    dispatch({
      type: UPDATE_NOTIF,
      payload: response.data?.notif,
    });
  }
  return response;
};

export const createNotifAction = async (dispatch :Dispatch, data: any) => {
  const url = '/notif';
  dispatch({
    type: NOTIF_LOADING,
  });
  const response = await postData(NOTIF_ERROR, url, dispatch, data, true);
  return response;
};
