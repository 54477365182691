import { Dispatch } from 'redux';
import {
  getData, postData, deleteData,
} from '.';
import {
  DELETE_HOLIDAY,
  GET_HOLIDAYS,
  HOLIDAY_ERROR,
  HOLIDAY_LOADING,
  POST_HOLIDAY,
  SET_TOAST,
} from './types';

export const getHolidaysAction = async (dispatch: Dispatch) => {
  const url = '/holiday';
  dispatch({
    type: HOLIDAY_LOADING,
    payload: GET_HOLIDAYS,
  });
  const response = await getData(HOLIDAY_ERROR, url, dispatch, true);
  if (response.data?.holidays) {
    dispatch({
      type: GET_HOLIDAYS,
      payload: response.data.holidays,
    });
  }
};

export const createHolidayAction = async (dispatch :Dispatch, data: any) => {
  const url = '/holiday';
  dispatch({
    type: HOLIDAY_LOADING,
    payload: POST_HOLIDAY,
  });
  const response = await postData(HOLIDAY_ERROR, url, dispatch, data, true);

  if (response?.data?.holiday) {
    dispatch({
      type: POST_HOLIDAY,
      payload: response.data?.holiday,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'Le jour férié a été créé',
        type: 'success',
      },
    });
  }
  return response;
};

export const deleteHolidayAction = async (dispatch: Dispatch, id: string) => {
  const url = `/holiday/${id}`;
  const response: any = await deleteData(HOLIDAY_ERROR, url, dispatch, true);
  if (response?.data) {
    dispatch({
      type: DELETE_HOLIDAY,
      payload: id,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'Le jour férié a été supprimé',
        type: 'success',
      },
    });
  }
  return response;
};
