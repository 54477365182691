import React, { useEffect, useRef, useState } from 'react';
import { useForm, get } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { BsCheck } from 'react-icons/bs';
import { TiArrowSortedDown } from 'react-icons/ti';
import { getWeekOptionsAction, importWeekAction } from '../../actions/shift';
import { ErrorField, StyledSelect } from '../../lib/HooksFormFields';
import { FavioriteWeek } from '../../types/shift';
import styles from './ImportModal.module.scss';
import { IOption } from '../../lib/HooksFormFields/StyledSelect/style';
import { getFormatedDate } from '../../utils';

const Select = ({
  handleChange,
  selectAll,
  options,
  selected,
  label,
}:{
  handleChange: (id: string) => void,
  selectAll: (id: string[]) => void,
  options: IOption[],
  selected: string[],
  label: string,
}) => {
  const optionsRef = useRef<null | HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const height = optionsRef?.current?.clientHeight;
  const isAll = selected.length === options.length;

  useEffect(() => {
    selectAll(options.map((d: any) => (d.value)));
  }, []);

  function handleIsAll() {
    let array : string[] = [];
    if (!isAll) {
      array = options.map((d: any) => (d.value));
    }
    selectAll(array);
  }

  return (
    <div className={styles.select}>
      <div className={styles.title}
      >
        <div className={styles.label}>
          <div className={styles.check}
            onClick={() => handleIsAll()}
          >
            {isAll
              && <BsCheck size={20}/>
            }
          </div>
          <label onClick={() => setIsOpen(!isOpen)}>{label} <span>({ isAll ? 'Tous' : selected.length })</span></label>
        </div>
        <div
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <TiArrowSortedDown />
        </div>
      </div>
      <div className={styles.list} style={{ height: isOpen ? height : 0 }}>
        <div ref={optionsRef} className={styles.options}>
          {options.filter((o: any) => o.value !== 'all').map((o: any) => <div
            key={o.value}
            className={styles.option}
            onClick={() => handleChange(o.value)}
          >
            <div className={styles.check}>
              {(selected.find((s) => s === o.value))
                && <BsCheck size={20}/>
              }
            </div>
            <p>{label === 'Jours' ? getFormatedDate(new Date(o.label)) : o.label}</p>
          </div>)}
        </div>
      </div>
    </div>
  );
};

const ImportModal = ({ close }:{ close: () => void }) => {
  const {
    watch, reset, control, handleSubmit, setValue, formState: { errors },
  } = useForm<any>();
  const dispatch = useDispatch();
  const form = watch();
  const [importErrors, setImportErrors] = useState<any>(null);
  const [selectedWeek, setSelectedWeek] = useState<any>(null);
  const { favioritesWeeks } = useSelector((store: any) => store.shiftsReducer);
  const { filters } = useSelector((store: any) => store.usersReducer);

  async function handleChangeOptions(name: string, value: string) {
    let array = form[name] || [];
    if (array.find((a: string) => a === value)) {
      array = array.filter((a: string) => a !== value);
    } else {
      array.push(value);
    }
    return setValue(name, array);
  }

  async function onSubmit() {
    setImportErrors(null);
    const res : any = await importWeekAction(dispatch, form);
    if (res.errors) {
      setImportErrors(res.errors);
    } else {
      close();
    }
  }

  useEffect(() => {
    getWeekOptionsAction(dispatch, filters.structure, filters.startDate, filters.endDate);
  }, [filters.structure, filters.startDate, filters?.endDate]);

  useEffect(() => {
    if (filters) {
      reset({
        currentWeek: {
          startDate: filters.startDate,
          endDate: filters.endDate,
        },
        lines: [],
        days: [],
        users: [],
        structure: filters.structure,
      });
    }
  }, [filters]);

  useEffect(() => {
    if (form?.importWeek?.startDate) {
      reset({
        ...form,
        lines: [],
        days: [],
        users: [],
      });
      const selected = favioritesWeeks.find(
        (w: any) => w.startDate === form?.importWeek?.startDate,
      );
      setSelectedWeek(selected);
    }
  }, [form?.importWeek?.startDate]);

  console.log(selectedWeek);

  return (
    <div className={styles.modale}>
      <h2>
        Importer une semaine
      </h2>
      {favioritesWeeks?.length > 0
        ? <>
        <div className={styles.field}>
          <label>Choisir une semaine</label>
          <StyledSelect
            name="importWeek"
            control={control}
            rules={{
              required: 'la semaine est requise',
            }}
            options={favioritesWeeks.map((w: FavioriteWeek) => ({
              label: w.label,
              value: {
                startDate: w.startDate,
                endDate: w.endDate,
              },
            }))}
          />
          {typeof get(errors, 'importWeek')?.message === 'string' && (
            <ErrorField message={get(errors, 'importWeek')?.message || ''}/>
          )}
        </div>
        {selectedWeek
          && <div className={styles.selects}>
            <Select
              label="Jours"
              handleChange={(id : string) => handleChangeOptions('days', id)}
              selectAll={(array: string[]) => setValue('days', array)}
              options={selectedWeek?.days}
              selected={form?.days}
            />

            <Select
              label="Collaborateurs"
              handleChange={(id : string) => handleChangeOptions('users', id)}
              selectAll={(array: string[]) => setValue('users', array)}
              options={selectedWeek?.users}
              selected={form?.users}
            />
            <Select
              label="Equipements"
              handleChange={(id : string) => handleChangeOptions('lines', id)}
              selectAll={(array: string[]) => setValue('lines', array)}
              options={selectedWeek?.lines}
              selected={form?.lines}
            />
        </div>
        }

        {importErrors
        && <div className={styles['errors-container']}>
            {importErrors?.imports?.length > 0
              && <div className={styles.users}>
                {importErrors?.imports.map((error: string) => (
                  <div key={error} className={styles.user}>
                    <p dangerouslySetInnerHTML={{ __html: error }} />
                  </div>
                ))}
              </div>
            }
            {importErrors?.users?.length > 0
              && <div className={styles.users}>
                {importErrors?.users.map((user: any) => (
                  <div className={styles.user} key={user.label}>
                    <p>{user.label}</p>
                    {user?.errors?.length > 0
                      && <div className={styles.errors}>
                        {user?.errors.map((error: string) => (
                          <div key={error} className={styles.error}>
                            <p dangerouslySetInnerHTML={{ __html: error }} />
                          </div>
                        ))}
                       </div>
                    }
                     {user?.alerts?.length > 0
                      && <div className={styles.alerts}>
                        {user?.alerts.map((alert: string) => (
                          <div key={alert} className={styles.alert}>
                            <p dangerouslySetInnerHTML={{ __html: alert }} />
                          </div>
                        ))}
                       </div>
                    }
                  </div>
                ))}
              </div>
            }
        </div>
        }
        <div className={styles.actions}>
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            className={styles.add}
          >
            Importer
          </button>
        </div>
      </>
        : <div className={styles.error}>
          <p>Vous n&apos;avez pas de semaines en favoris.</p>
        </div>
      }
    </div>
  );
};

export default ImportModal;
