import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import styles from './SelectTags.module.scss';

interface IOption {
  value: string,
  label: string,
  color: string,
}

interface SelectTagsProps extends UseControllerProps {
  name: string,
  rules: any,
  widthLabel?: string,
  inline?: boolean
  label?: string,
  placeholder?: string,
  defaultValue?: string | any,
  className?: string,
  handleChange?: (str: string) => void,
  handleFocus?: () => void,
  maxlength?: number,
  isMulti?: boolean,
  options: IOption[],
}

const SelectTags:React.FC<SelectTagsProps> = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  label = null,
  defaultValue,
  className,
  maxlength,
  options = [],
  isMulti = false,
}) => {
  const {
    field: {
      value = isMulti ? [] : '',
      ref,
      onChange,
    },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const inputProps: Record<string, any> = {};

  if (maxlength) {
    inputProps.maxLength = maxlength;
  }

  function getTagStyle(opt: IOption) {
    if (opt.value === value) {
      return {
        backgroundColor: opt.color,
      };
    }
    if (isMulti && value && value?.find((v: string) => v === opt.value)) {
      return {
        backgroundColor: opt.color,
      };
    }
    return {
      backgroundColor: '#8D97A0',
    };
  }

  function handleChange(val : any) {
    if (!isMulti) {
      return onChange(val);
    }
    if (isMulti && value && value?.find((v: string) => v === val)) {
      return onChange(value.filter((v : string) => v !== val));
    }
    return onChange([...value, val]);
    return null;
  }
  return (
    <div
      className={
      inline ? `${className ? styles[className] : ''} ${styles['container-input']} ${styles.inline}`
        : `${className ? styles[className] : ''} ${styles['container-input']}`
    }
    >
      {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <div ref={ref} className={styles.tags}>
        {options.map((opt) => <button
          key={opt.label}
          type='button'
          style={getTagStyle(opt)}
          onClick={() => handleChange(opt.value)}
          >
          <p>{opt.label}</p>
        </button>)}
      </div>
    </div>
  );
};

export default SelectTags;
