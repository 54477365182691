import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DayPicker } from 'react-day-picker';
import { fr } from 'date-fns/locale';
import 'react-day-picker/dist/style.css';
import { format } from 'date-fns';
import calendar from './images/calendar.svg';
import styles from './DayPicker.module.scss';

export default function CustomDayPicker({
  handleChangeFilters,
}:{
  handleChangeFilters: (d: any) => void
}) {
  const { filters } = useSelector((store: any) => store.usersReducer);
  const day = filters?.startDate ? new Date(filters.startDate) : null;
  const [isOpen, setIsOpen] = useState(false);

  function handleChangeDate(selectedDate: Date | undefined) {
    if (!selectedDate) {
      return null;
    }

    const date = new Date(`${format(selectedDate, 'yyyy-MM-dd')}T00:00:00.000Z`);
    const dayOfWeek = new Date(date).getDay();
    const diff = (dayOfWeek === 1) ? 0 : dayOfWeek - 1;
    const monday = diff === -1
      ? new Date(new Date(date).setDate(date.getDate() - 6))
      : new Date(new Date(date).setDate(date.getDate() - diff));
    const sunday = new Date(new Date(date).setDate(monday.getDate() + 6));

    handleChangeFilters({
      startDate: filters?.endDate ? monday.toISOString() : date.toISOString(),
      endDate: filters?.endDate ? sunday.toISOString() : null,
    });
    return setIsOpen(false);
  }
  return (
    <div className={styles.container}>
      {day
      && <>
      <button
        type='button'
        className={styles.toggle}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img src={calendar} alt="calendar" />
        <p>{day && day.getDate()}</p>
      </button>
      {isOpen
        && <div className={styles.picker}
          onMouseLeave={() => setIsOpen(!isOpen)}
        >
          <DayPicker
            mode="single"
            locale={fr}
            selected={day}
            onSelect={(date: Date | undefined) => handleChangeDate(date)}
          />
        </div>
      }
      </>
      }
    </div>
  );
}
