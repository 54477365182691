import { format } from 'date-fns';
import { IContract } from '../types/contract';

const get = (obj: Record<string, any> = {}, path: string | string[]): any => {
  const properties = Array.isArray(path) ? path : path.split('.');
  return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
};

export default get;

export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

export const getDurationLabel = (number: number) => {
  const hours = Math.trunc(number);
  const minuts = 60 * (number - hours);
  return `${hours}H${minuts > 0 ? minuts.toFixed() : ''}`;
};

export const getFormatedDate = (date: Date) => new Intl.DateTimeFormat('fr', {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}).format(date);

export const getWeekNumber = (date: Date) => {
  const startDate = new Date(date);
  const year = startDate && new Date(startDate.getFullYear(), 0, 1);
  const days = Math.floor((startDate.getTime() - year.getTime()) / (24 * 60 * 60 * 1000));
  const week = Math.ceil((startDate.getDay() + 1 + days) / 7);
  return week;
};

export function getActiveContractsBetweenDates(
  contrats : IContract[],
  startDate: Date,
  endDate = null,
) {
  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : null;
  return contrats.filter((contrat) => {
    const contratStart = new Date(contrat.startDate);
    const contratEnd = contrat.endDate ? new Date(contrat.endDate) : null;
    if (contratEnd && contratEnd < start) {
      return null;
    }
    if (!contratEnd && contratStart <= start) {
      return contrat;
    }

    // Vérifie si le contrat est actif pendant une période entre startDate et endDate
    return (
      contratStart <= (end || new Date())
      && (!contratEnd || contratEnd >= start)
    );
  });
}

export const getBrightness = (color: string) => {
  const hexColor = color.replace('#', '');

  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

  return brightness > 128;
};

export function generateWeeklyObjects(startDate: Date, endDate: Date) {
  // Convertir les dates en objets Date pour faciliter les calculs
  const start = new Date(startDate);
  const end = new Date(endDate);

  // S'assurer que start est un lundi
  while (start.getDay() !== 1) {
    start.setDate(start.getDate() - 1); // Remonter jusqu'au lundi précédent
  }

  // S'assurer que end est un dimanche
  while (end.getDay() !== 0) {
    end.setDate(end.getDate() + 1); // Aller jusqu'au dimanche suivant
  }

  const weeks = [];

  while (start <= end) {
    // Calculer la fin de la semaine courante (dimanche)
    const weekEnd = new Date(start);
    weekEnd.setDate(weekEnd.getDate() + 6);

    // Ajouter l'objet semaine au tableau
    weeks.push({
      start: new Date(`${format(new Date(start), 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString(),
      end: new Date(`${format(new Date(weekEnd), 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString(),
      weekNumber: getWeekNumber(start),
    });

    // Passer à la semaine suivante
    start.setDate(start.getDate() + 7);
  }

  return weeks;
}
