/* eslint-disable no-restricted-syntax */
import {
  GET_HOLIDAYS,
  POST_HOLIDAY,
  HOLIDAY_LOADING,
  HOLIDAY_ERROR,
  DELETE_HOLIDAY,
} from '../actions/types';
import { ActionReducer } from '../types';
import { IHoliday } from '../types/holiday';

interface HolidayState {
  holidays: IHoliday[],
  isLoading: string[],
  error: string | null,
}

const DEFAULT_STATE: HolidayState = {
  holidays: [],
  isLoading: [],
  error: null,
};

// eslint-disable-next-line default-param-last
const holidayReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  const { holidays } = state;
  switch (action.type) {
    case GET_HOLIDAYS:
      updatedState = {
        ...state,
        holidays: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case POST_HOLIDAY:
      updatedState = {
        ...state,
        holidays: [action.payload, ...holidays],
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case DELETE_HOLIDAY:
      updatedState = {
        ...state,
        holidays: holidays.filter((d) => d._id !== action.payload),
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case HOLIDAY_LOADING:
      updatedState = {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case HOLIDAY_ERROR:
      updatedState = { ...state, error: action.payload || null };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default holidayReducer;
