import React from 'react';
import { Controller } from 'react-hook-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import InputNumber from 'rc-input-number';
import ErrorField from '../ErrorField';
import styles from './input-text.module.scss';

interface IInputNumber {
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any,
  label?: string | null,
  inline?: boolean,
  widthLabel?: string | null,
  placeholder?: string,
  defaultValue?: string,
  className?: string,
  unit?: string,
  error?: string,
  step?: string,
  max?: number | null,
  min?: number | null,
}

function CustomInputNumber({
  name,
  control,
  rules,
  defaultValue = '',
  widthLabel = null,
  label = null,
  unit = '',
  placeholder,
  className = 'primary',
  error = '',
  step = '',
  max = null,
  min = 0,
}: IInputNumber) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: {
          value = '',
          ref,
          onChange,
        },
      }) => (
        <>
          <div className={`${className ? styles[className] : ''} ${error ? styles.error : ''} ${styles['container-input']}`}>
            {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
            <InputNumber
              ref={ref}
              value={value}
              step={step}
              max={max}
              min={min}
              type="number"
              onChange={(val) => onChange(val)}
              className={styles.input}
              placeholder={placeholder}
            />
            {unit && <div className={styles.unit}>
              {unit}
            </div>}
          </div>
          {error && <ErrorField message={error}/>}
        </>
      )}
    />
  );
}

export default CustomInputNumber;
