import React, { useRef } from 'react';
import { useFormContext, get } from 'react-hook-form';
import { IoTrash } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteStructureAction } from '../../actions/structures';
import {
  ErrorField,
  InputFormatPattern,
  InputText,
  StyledSelect,
} from '../../lib/HooksFormFields';
import Checkbox from '../../lib/HooksFormFields/Checkbox';
import InputMedia from '../../lib/HooksFormFields/InputMedia';
import { IOption } from '../../lib/HooksFormFields/StyledSelect/style';
import ModalStandalone, { IModalHandle } from '../../lib/ModalStandalone';
import styles from './Structure.module.scss';

const StructureForm = ({ onSubmit }: { onSubmit: any }) => {
  const navigate = useNavigate();
  const id = useParams()?.id;
  const dispatch = useDispatch();
  const {
    structure,
  } = useSelector((store: any) => store.structureReducer);
  const {
    list,
  } = useSelector((store: any) => store.shiftsReducer);
  const {
    user,
  } = useSelector((store: any) => store.authReducer);

  const hoursOptions : IOption[] = list?.shifts?.map(
    (s: IOption) => ({ label: s.label, value: s.label, start: s.start }),
  ) || [];

  const { watch, control, formState: { errors } } = useFormContext<any>();

  const form = watch();

  const modalRef = useRef<IModalHandle | null>(null);

  async function deleteStructure() {
    if (!id) return null;
    const res = await deleteStructureAction(dispatch, id);
    if (res) {
      return navigate('/admin/site');
    }
    return null;
  }
  return (
    <div className={`${styles.container} ${styles.active}`}>
      <div className={styles.bg}/>
      <div className={styles.content}>
        <div className={styles.avatar}>
          {structure?._id
            && <InputMedia
                name="picture"
                media={form?.picture}
                className={'structure'}
                handleChange={onSubmit}
                submit={onSubmit}
                disabled={!form._id || true}
              />
          }
          <div className={styles.name}>
            <h1>{structure?._id && form?.name}</h1>
            <p>{structure?._id && form?.address}
              {structure?._id && form?.city && <>
                  <br/>
                  {form?.postalCode} {form?.city}
                </>
              }
            </p>
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.profile}>
            <div className={styles.col}>
              <div className={styles['container-field']}>
                <InputText
                  name="name"
                  control={control}
                  label="Nom du site"
                  rules={{
                    required: 'Veuillez renseigner le nom',
                  }}
                />
              {typeof get(errors, 'name')?.message === 'string' && (
                <ErrorField message={get(errors, 'name')?.message || ''}/>
              )}
              </div>
            </div>
            <div className={styles.col}>

            </div>
          </div>
          <div className={styles.profile}>
            <div className={`${styles.col} ${styles.full}`}>
              <div className={styles['container-field']}>
                <InputText
                  name="address"
                  control={control}
                  label="Adresse"
                  rules={{
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.profile}>
            <div className={styles.col}>
              <div className={styles['container-field']}>
                <InputText
                  name="city"
                  control={control}
                  label="Ville"
                  rules={{
                  }}
                />
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles['container-field']}>
                <InputText
                  name="postalCode"
                  control={control}
                  label="Code postal"
                  rules={{
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.profile}>
            <div className={styles.col}>
              <div className={styles['container-field']}>
                <InputFormatPattern
                  name="phone"
                  label="Téléphone"
                  control={control}
                  format="## ## ## ## ##"
                />
              </div>
            </div>
          </div>
          <h2>Horaires du planning</h2>
          <div className={styles.profile}>
            <div className={styles.col}>
              <div className={styles['container-field']}>
                <StyledSelect
                  name="schedules.start"
                  control={control}
                  label="Heure de début"
                  options={hoursOptions.filter((h, i) => h.label?.includes(':00') && i < 70)}
                  rules={{
                    required: 'Veuillez renseigner l\'heure de début',
                  }}
                />
                {typeof get(errors, 'schedules.start')?.message === 'string' && (
                  <ErrorField message={get(errors, 'schedules.start')?.message || ''}/>
                )}
              </div>
              <div className={styles['container-field']}>
                <StyledSelect
                  name="schedules.end"
                  control={control}
                  label="Heure de fin"
                  options={hoursOptions.filter((h, i) => h.label?.includes(':00') && i > 150)}
                  rules={{
                    required: 'Veuillez renseigner l\'heure de fin',
                  }}
                />
                {typeof get(errors, 'schedules.end')?.message === 'string' && (
                  <ErrorField message={get(errors, 'schedules.end')?.message || ''}/>
                )}
              </div>
            </div>
          </div>
          <h2>Partage du planning</h2>
          <div className={styles.profile}>
            <div className={styles.col}>
              <div className={styles['container-field']}>
                <Checkbox
                  name="isNextWeeks"
                  control={control}
                  label="Diffuser les semaines au-delà de 4 semaines"
                  rules={{}}
                />
              </div>
            </div>
          </div>
          {user?.role === 'Admin'
            && <div className={styles.profile}>
              <button
                type="button"
                className={styles.delete}
                onClick={() => modalRef.current?.toggle()}
              >
                <IoTrash /> Supprimer le site
              </button>
            </div>
          }
        </div>
      </div>
      <ModalStandalone ref={modalRef}>
        <div className={styles['delete-modale']}>
          <p>
            La suppression du site est définitive.<br />
            les plannings et les utilisteurs associés seront également supprimés
          </p>
          <button
            type="button"
            className={`${styles.btn} ${styles.warn}`}
            onClick={() => deleteStructure()}
          >
            Confirmer la suppression du site
          </button>
        </div>
      </ModalStandalone>
    </div>
  );
};

export default StructureForm;
