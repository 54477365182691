/* eslint-disable react/prop-types */
import * as IconsExported from 'react-icons/io';

const Icon = ({ selectedIcon, color = '#000000' }) => {
  const list = Object.entries(IconsExported);
  if (list.find(([key]) => key === selectedIcon)?.length) {
    const icon = list.find(([key]) => key === selectedIcon)[1];
    return icon({ color, size: 20 });
  }
  if (selectedIcon === 'break') {
    const icon = list?.find(([key]) => key === 'IoIosCafe')[1];
    return icon({ color, size: 20 });
  }
  if (selectedIcon === 'rest') {
    const icon = list?.find(([key]) => key === 'IoIosBed')[1];
    return icon({ color, size: 20 });
  }
  if (selectedIcon === 'paid vacation') {
    const icon = list?.find(([key]) => key === 'IoIosSunny')[1];
    return icon({ color, size: 27 });
  }
  if (selectedIcon === 'other') {
    const icon = list?.find(([key]) => key === 'IoIosHome')[1];
    return icon({ color, size: 20 });
  }
  return null;
};

export default Icon;
