import React, { useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllStructuresAction } from '../../actions/structures';
import { GET_STRUCTURE } from '../../actions/types';

import Header from '../../components/Header';
import { IStructure } from '../../types/structure';

import styles from './StructuresList.module.scss';

const Card = ({ structure }: { structure : IStructure }) => (
    <Link to={`/admin/site/${structure._id}`} className={styles.card}>
      <div className={styles.picture}>
        {structure?.picture?.path
          && <img src={`${process.env.REACT_APP_API_URL}/public/${structure.picture.path}`} alt={structure.picture.alt}/>
        }
      </div>
      <h3>{structure?.name}</h3>
      <h4>{structure?.address}</h4>
    </Link>
);

const StructuresList = () => {
  const dispatch = useDispatch();
  const { structuresList } = useSelector((store: any) => store.structureReducer);
  const { user } = useSelector((d: any) => d.authReducer);

  useEffect(() => {
    getAllStructuresAction(dispatch);
    dispatch({
      type: GET_STRUCTURE,
      payload: null,
    });
  }, []);

  return (
    <div className={styles.container}>
      <Header name='Sites' isProfile />
      <div className={styles.content}>
        <div className={styles.title}>
          <h1>Sites</h1>
          {user.role === 'Admin'
          && <Link to="/admin/site/create" className={styles.new}>
            <div className={styles.icon}>
              <FaPlus />
            </div>
            Nouveau
          </Link>
        }
        </div>
        <div className={styles.list}>
          <div className={styles.grid}>
            {structuresList.map((s: IStructure) => <Card key={s._id} structure={s} />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StructuresList;
