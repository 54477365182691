/* eslint-disable no-restricted-syntax */
import {
  GET_USER_CONTRACTS,
  POST_CONTRACT,
  CONTRACT_LOADING,
  CONTRACT_ERROR,
  PUT_CONTRACT,
  GET_CONTRACT,
  DELETE_CONTRACT,
  GET_STRUCTURE_CONTRACTS,
} from '../actions/types';
import { ActionReducer } from '../types';
import { IContract } from '../types/contract';

interface ShiftState {
  userContracts: IContract[],
  contracts: IContract[],
  contract: IContract | null,
  isLoading: string[],
  error: string | null,
}

const DEFAULT_STATE: ShiftState = {
  userContracts: [],
  contracts: [],
  contract: null,
  isLoading: [],
  error: null,
};

// eslint-disable-next-line default-param-last
const shiftsReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  const { userContracts } = state;
  const contractIndex = userContracts?.findIndex((c) => c._id === action.payload?._id);
  switch (action.type) {
    case GET_STRUCTURE_CONTRACTS:
      updatedState = {
        ...state,
        contracts: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case GET_USER_CONTRACTS:
      updatedState = {
        ...state,
        userContracts: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case GET_CONTRACT:
      updatedState = {
        ...state,
        contract: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case POST_CONTRACT:
      updatedState = {
        ...state,
        userContracts: [action.payload, ...userContracts],
        contract: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case PUT_CONTRACT:
      if (contractIndex !== -1) {
        userContracts[contractIndex] = action.payload;
      }
      updatedState = {
        ...state,
        userContracts,
        contract: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case DELETE_CONTRACT:
      updatedState = {
        ...state,
        userContracts: userContracts.filter((d) => d._id !== action.payload),
        contract: null,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case CONTRACT_LOADING:
      updatedState = {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case CONTRACT_ERROR:
      updatedState = { ...state, error: action.payload || null };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default shiftsReducer;
