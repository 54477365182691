import React, { useEffect, useState } from 'react';
import { FaCrown, FaPlus, FaStar } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllStructuresAction } from '../../actions/structures';
import { SET_USERS_FILTERS } from '../../actions/types';

import { postUsersFilteredAction } from '../../actions/users';
import DropDown from '../../components/DropDown/DropDown';

import Header from '../../components/Header';
import Search from '../../components/Search/Search';
import { IPole, IStructure } from '../../types/structure';
import { IUser } from '../../types/user';

import styles from './UsersList.module.scss';

const sortOptions = [
  {
    value: 'asc',
    label: 'Trier par A - Z',
  },
  {
    value: 'desc',
    label: 'Trier par Z - A',
  },
];
export const UserCard = ({
  user,
  className = null,
}:{
  user : IUser,
  className?: string | null
}) => (
    <Link to={`/admin/user/${user._id}`}
      className={`
      ${styles.card}
      ${className ? styles[className] : ''}
      ${user.isActive ? '' : styles.archived}
    `}>
      {user.role !== 'Member'
        && <div className={styles.role}>
          {user.role === 'Admin' && <FaCrown />}
          {user.role === 'Owner' && <FaStar />}
        </div>
      }
      <div className={styles.picture}>
        {user?.picture?.path
          ? <img src={`${process.env.REACT_APP_API_URL}/public/${user.picture.path}`} alt={user.picture.alt}/>
          : <p>{user?.profile?.firstName[0]}.{user?.profile?.lastName[0]}</p>
        }
      </div>
      <div className={styles.infos}>
        <h3>{user?.profile?.firstName} {user?.profile?.lastName}</h3>
        {user?.structure?.name && <h4>{user?.structure?.name}</h4>}
        {user.role === 'Admin' && <h4>Administrateur</h4>}
      </div>
    </Link>
);

const UsersList = () => {
  const dispatch = useDispatch();
  const { usersList, filters } = useSelector((store: any) => store.usersReducer);
  const { structuresList } = useSelector((store: any) => store.structureReducer);
  const { user } = useSelector((store: any) => store.authReducer);
  const poles = structuresList.find((s: IStructure) => s._id === filters.structure)?.poles;
  const [search, setSearch] = useState(filters.search);

  function handleChangeFilters(obj: any) {
    dispatch({
      type: SET_USERS_FILTERS,
      payload: {
        ...filters,
        ...obj,
      },
    });
  }

  useEffect(() => () => {
    handleChangeFilters({ search: '' });
  }, []);

  useEffect(() => {
    postUsersFilteredAction(dispatch, filters);
    getAllStructuresAction(dispatch);
  }, [filters]);

  useEffect(() => {
    const saveToServer = () => {
      // Step 5: Implement the auto-save function (example: save to a server)
      // Replace this with your actual saving logic (e.g., API call).
      handleChangeFilters({ search });
    };

    // Auto-save after a delay (e.g., 1 second) of no input changes
    const timeoutId = setTimeout(saveToServer, 1000);

    // Cleanup: Cancel the timeout if the input changes again before the delay
    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  return (
    <div className={styles.container}>
      <Header name='Membres' isProfile />
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.members}>
            <h1>Membres</h1>
            <p>{usersList?.length}</p>
          </div>

          <Link to="/admin/user/create" className={styles.new}>
            <div className={styles.icon}>
              <FaPlus />
            </div>
            Nouveau
          </Link>
        </div>
        <div className={styles.filters}>
          <div className={styles.col}>
            <Search
              value={search}
              handleChange={(value: any) => setSearch(value)}
            />
          </div>
          <div className={styles.col}>
            {user?.role === 'Admin'
              && <DropDown
                onChange={(value: any) => handleChangeFilters({ structure: value, poles: [] })}
                selected={filters?.structure}
                options={[
                  { label: 'Tous les sites', value: null },
                  ...structuresList.map((s : IStructure) => ({ label: s.name, value: s._id })),
                ]}
              />
            }
          </div>
          <div className={styles.col}>
            {poles
              && <DropDown
                onChange={(value: any) => handleChangeFilters({ poles: value })}
                selected={filters?.poles}
                multiLabel={filters?.poles.length > 0 ? 'catégories séléctionnées' : 'Catégories'}
                isMulti
                options={[
                  { label: 'Toutes les catégories', value: null },
                  ...poles.map((s : IPole) => ({
                    label: s.name,
                    value: s._id,
                    color: s.color,
                  })),
                ]}
              />
            }
          </div>
          <div className={styles.col}>
            {sortOptions
              && <DropDown
                onChange={(value: any) => handleChangeFilters({ sortDir: value })}
                selected={filters.sortDir}
                options={sortOptions}
              />
            }
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.grid}>
            {usersList.map((u: IUser) => <UserCard key={u._id} user={u} />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
