import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { useFormContext } from 'react-hook-form';
import { IPole } from '../../types/structure';
import Icon from '../Icon/Icon';
import styles from './AddPoleModal.module.scss';

const defaultPoles = [
  {
    name: 'Accueil',
    icon: 'IoIosCall',
    color: '#5D5FEF',
    activities: [],
    isActive: true,
  },
  {
    name: 'Entretien',
    icon: 'IoIosLeaf',
    color: '#61AD92',
    activities: [],
    isActive: true,
  },
  {
    name: 'Patinoire',
    icon: 'IoMdWalk',
    color: '#A386DE',
    activities: [],
    isActive: true,
  },
  {
    name: 'Fitness',
    icon: 'IoMdFitness',
    color: '#E57D66',
    activities: [],
    isActive: true,
  },
  {
    name: 'Technique',
    icon: 'IoMdBuild',
    color: '#A73F3F',
    activities: [],
    isActive: true,
  },
  {
    name: 'Bassin',
    icon: 'IoMdWater',
    color: '#52A4F0',
    activities: [],
    isActive: true,
  },
];

const AddPoleModal = ({
  onSubmit,
  createPole,
  close,
}: {
  onSubmit: any,
  createPole: any,
  close: () => void
}) => {
  const { watch, setValue } = useFormContext<any>();
  const form = watch();

  async function handleChange(pole: IPole) {
    const poles = form.poles || [];
    await setValue('poles', [...poles, pole]);
    await onSubmit();
    return close();
  }

  return (
    <div className={styles.modale}>
      <h2>
        Ajouter une catégorie
      </h2>
      <div className={styles.poles}>
        {defaultPoles.map((d: IPole, i: number) => <button
          key={`pole-${i}`}
          onClick={() => handleChange(d)}
          type="button"
          className={form?.poles?.find((p: IPole) => p.name === d.name) ? styles.active : ''}
          >
          <div
            className={styles.icon}
            style={{ backgroundColor: d.color }}
          >
            <Icon
              selectedIcon={d.icon}
              color="#FFFFFF"
            />
          </div>
          <p>{d.name}</p>
        </button>)}
      </div>
      <div className={styles.actions}>
        <button
          type="button"
          onClick={() => createPole()}
        >
          <span>Créer une nouvelle catégorie</span>
          <div className={styles.icon}>
            <FaPlus />
          </div>
        </button>
        <button
          type="button"
          onClick={() => onSubmit()}
          className={styles.add}
        >
          Appliquer
        </button>
      </div>
    </div>
  );
};

export default AddPoleModal;
