import {
  GET_STRUCTURE,
  CREATE_STRUCTURE,
  UPDATE_STRUCTURE,
  GET_ALL_STRUCTURE,
  STRUCTURE_LOADING,
  STRUCTURE_ERROR,
  GET_STRUCTURE_POOLS,
  CREATE_POOL,
  UPDATE_POOL,
} from '../actions/types';
import { ActionReducer } from '../types';
import { IPool, IStructure } from '../types/structure';

interface StructureState {
  structure: IStructure | null,
  pools: IPool[] | null,
  structuresList: IStructure[],
  isLoading: string[],
  error: string | null,
}

const DEFAULT_STATE: StructureState = {
  structure: null,
  structuresList: [],
  pools: null,
  isLoading: [],
  error: null,
};

// eslint-disable-next-line default-param-last
const structureReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  const pools = state?.pools?.length ? state?.pools : [];
  const poolIndex = pools.findIndex((p) => p._id === action?.payload?._id);
  switch (action.type) {
    case GET_ALL_STRUCTURE:
      updatedState = {
        ...state,
        structuresList: action.payload,
        pool: null,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case CREATE_STRUCTURE:
    case UPDATE_STRUCTURE:
    case GET_STRUCTURE:
      updatedState = {
        ...state,
        structure: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case UPDATE_POOL:
      if (poolIndex !== -1) {
        pools[poolIndex] = action.payload;
      }
      updatedState = {
        ...state,
        pools: [...pools],
      };
      break;
    case CREATE_POOL:
      updatedState = {
        ...state,
        pools: [...pools, action.payload],
      };
      break;
    case GET_STRUCTURE_POOLS:
      updatedState = {
        ...state,
        pools: action.payload,
      };
      break;
    case STRUCTURE_LOADING:
      updatedState = {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case STRUCTURE_ERROR:
      updatedState = { ...state, error: action.payload };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default structureReducer;
