/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  useState, useEffect, forwardRef, useImperativeHandle, useRef,
} from 'react';
import { createPortal } from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { animated, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';

import styles from './modal.module.scss';

export type IModalHandle = {
  toggle: () => void,
  open: () => void,
  close:() => void,
  isOpen: boolean,
};

const DraggableModal: any = ({
  children,
  isDraggable = false,
}: {
  children: JSX.Element,
  isDraggable: boolean,
}) => {
  const [{
    xy, opacity, cursor, zIndex, touchAction,
  }, set] = useSpring(() => ({
    xy: [0, 0],
    scale: 1,
    opacity: 1,
    cursor: 'grab',
    zIndex: 1000,
    touchAction: 'none',
  }));

  const bind = useDrag((
    state: any,
  ) => {
    if (state.dragging && state.delta[0] !== 0 && state.delta[1] !== 0) {
      set({
        xy: state.offset,
        scale: state.down ? 1.1 : 1,
        cursor: state.down ? 'grabbing' : 'grab',
      });
    }
  });

  if (!isDraggable) {
    return (
      <>
       {children}
      </>
    );
  }

  return (
    <animated.div
      {...bind()}
      style={{
        opacity,
        cursor,
        transform: 'translate(-50%,-50%)',
        top: xy.to((x, y) => `calc(50% + ${y}px)`),
        left: xy.to((x) => `calc(50% + ${x}px)`),
        position: 'fixed',
        zIndex,
        touchAction,
      }}
    >
      {children}
    </animated.div>
  );
};

const ModalStandalone = forwardRef<IModalHandle, any>(({
  children,
  isDraggable = false,
  domNode = 'modal',
  closeCallBack,
}:{
  children: JSX.Element,
  domNode: string,
  isDraggable: boolean
  closeCallBack?: () => void,
}, ref) => {
  const modalRoot = document.getElementById(domNode);
  const [open, setOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [toggleAnimation, setToggleAnimation] = useState(false);
  // const [prevPos, setPrevPos] = useState([0, 0]);
  useImperativeHandle(ref, () => ({
    toggle: () => {
      setOpen((state) => !state);
      if (open && closeCallBack) {
        closeCallBack();
      }
    },
    open: () => setOpen(true),
    isOpen: open,
    close: () => {
      setOpen(false);
      if (closeCallBack) {
        closeCallBack();
      }
    },
  }));

  function toggle() {
    setOpen((state) => !state);
    if (open && closeCallBack) {
      closeCallBack();
    }
  }

  function close() {
    setToggleAnimation(false);
  }
  const spring = useSpring({
    opacity: toggleAnimation ? 1 : 0,
    onRest: (state) => {
      if (state.value.opacity === 0) toggle();
    },
  });

  useEffect(() => {
    if (!ref) console.error('ModalStandalone components need require a ref');
  }, [ref]);

  useEffect(() => {
    if (!modalRoot) {
      console.error(`Can't find the dom element (#${domNode}) where this modal should be mount \nYou should add a div with id : "${domNode}" to public/index.html
     `);
    }
  }, [modalRoot, domNode]);

  useEffect(() => {
    if (open) {
      setToggleAnimation(true);
    }
  }, [open]);

  return (
    <>
      {modalRoot
        && (
        <>
          {createPortal(
            <>
              {(ref && open)
                && (
                <>
                  <animated.div
                    style={{ ...spring }}
                    className={styles['overlay-modal']}
                    onClick={close}
                  />
                  <DraggableModal
                    isDraggable={isDraggable}
                  >
                    <div
                      className={`${!isDraggable ? styles.modal : ''}`}
                      ref={modalRef}
                    >
                      <animated.div
                        style={{
                          opacity: spring.opacity,
                        }}
                        className={styles.container}
                      >
                        {children}
                        <button onClick={close} className={styles.close}>
                          Fermer
                        </button>
                      </animated.div>
                    </div>
                  </DraggableModal>
                </>
                )}
            </>,
            modalRoot,
          )}
        </>
        )}
    </>
  );
});

export default ModalStandalone;
