import React, { useState } from 'react';
import { BiExport, BiLoaderAlt } from 'react-icons/bi';
import styles from './export.module.scss';

const ExportButton = ({
  callBack,
  isPreview = false,
}: {
  callBack: () => {},
  isPreview?: boolean
}) => {
  const [isLoading, setIsLoading] = useState(false);
  async function handleClick() {
    setIsLoading(true);
    const res = await callBack();
    if (res) {
      setIsLoading(false);
    }
  }
  return (
    <button
      type="button"
      className={`${styles.export} ${isPreview ? styles?.preview : ''}`}
      onClick={() => handleClick()}
    >
      <div className={`${styles.icon} ${isLoading ? styles.rotate : ''}`}>
        {isLoading ? <BiLoaderAlt /> : <BiExport />}
      </div>
      {isPreview && <p>{isLoading ? 'Traitement en cours' : 'Exporter en PDF'}</p>}
    </button>
  );
};

export default ExportButton;
