/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Select from 'react-select';
import {
  Controller,
} from 'react-hook-form';
import styles from './styled-select.module.scss';
import { style1, style2 } from './style';
import ErrorField from '../ErrorField';

export interface IOption {
  value: string,
  label: string,
  start?: number,
  end?: string | number,
}

interface ISelect {
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules?: any,
  inline?: Boolean | undefined | null,
  isMulti?: Boolean | undefined | null,
  placeholder?: string,
  label?: string | null,
  error?: string | null,
  className?: string | null,
  style?: string | null,
  options: IOption[] | any,
}

function StyledSelect({
  name,
  control,
  rules,
  isMulti = false,
  placeholder = '',
  className = 'primary',
  error = '',
  label,
  options = [],
}: ISelect) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: {
          onChange, value,
        },
      }) => (
        <>
        <div className={`${styles['container-select']} ${error ? styles.error : ''}  ${className ? styles[className] : ''}`}>
          { label && <label>{label}</label>}
          <div className={styles.input}>
            <Select
              value={isMulti
                ? options?.filter((c: IOption) => value?.find((v: string) => v === c.value))
                : options.find(
                  (c: IOption) => c.value === value
                  || JSON.stringify(value) === JSON.stringify(c.value),
                )}
              onChange={(val) => onChange(isMulti ? val.map((v: IOption) => v.value) : val.value)}
              options={options}
              className={`${styles.select}`}
              isMulti={!!isMulti}
              placeholder={placeholder}
              styles={className === 'primary' ? style1 : style2}
            />
          </div>
        </div>
        {error && <ErrorField message={error}/>}
        </>
      )}
    />
  );
}

export default StyledSelect;
