import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './Header.module.scss';

export default function Header({ name, isProfile = false }: {
  name: string,
  isProfile?: boolean,
}) {
  const { user } = useSelector((d: any) => d.authReducer);
  return (
    <header className={styles.header} >
      <h1>{name}</h1>
      {isProfile && user
      && <Link to={user.role === 'Member' ? '/member/profile' : '/admin/profile'}>
        <div className={styles.profile}>
          {user?.picture?.path
            ? <>
              <img src={`${process.env.REACT_APP_API_URL}/public/${user.picture?.path}`} alt='avatar' />
            </>
            : <p>{user?.profile?.firstName[0]}.{user?.profile?.lastName[0]}</p>
          }
        </div>
      </Link>
      }
    </header>
  );
}
