import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  WiDaySunny, WiRain, WiDayCloudy, WiDayShowers, WiDayThunderstorm,
} from 'react-icons/wi';
import styles from './Weather.module.scss';

export default function Weather() {
  const [currentWeather, setCurrentWeather] = useState<any>();

  async function getCurrentWeather() {
    navigator.geolocation.getCurrentPosition(async (pos) => {
      const { latitude, longitude } = pos.coords;

      const url = `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current_weather=true&hourly=temperature_2m`;
      const weatherData = await axios.get(url, {});
      const weather = weatherData?.data;
      // const mapUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=fr"`;
      // const mapData = await axios.get(mapUrl, {});
      const data : any = {};
      const code = weather?.current_weather?.weathercode;

      if (code === 0) {
        data.icon = 'clear';
        data.message = 'Ciel clair';
      }
      if (code >= 1 && code <= 3) {
        data.icon = 'cloudy';
        data.message = 'Partielement nuageux';
      }
      if (code >= 45 && code <= 57) {
        data.icon = 'showers';
        data.message = 'Averses';
      }
      if (code >= 61 && code <= 94) {
        data.icon = 'rain';
        data.message = 'Pluie';
      }
      if (code >= 95 && code <= 99) {
        data.icon = 'storm';
        data.message = 'Orage';
      }
      data.min = Math.min(...weather.hourly.temperature_2m);
      data.max = Math.max(...weather.hourly.temperature_2m);
      // data.city = mapData.data.city;
      setCurrentWeather(data);
    }, (err) => console.log(err));
  }

  useEffect(() => {
    getCurrentWeather();
  }, []);

  return (
    <div className={styles.container}>
      {currentWeather
      && <>
      <div className={styles.icon}>
        {currentWeather?.icon === 'rain' && <WiRain />}
        {currentWeather?.icon === 'clear' && <WiDaySunny />}
        {currentWeather?.icon === 'cloudy' && <WiDayCloudy />}
        {currentWeather?.icon === 'showers' && <WiDayShowers />}
        {currentWeather?.icon === 'storm' && <WiDayThunderstorm />}
      </div>
      <div className={styles.deg}>
        <p>
          {currentWeather?.message}
        </p>
        <p>
          min
          {' '}
          <span>
          {currentWeather?.min}
          °
          </span>
          {' '}
          max
          {' '}
          <span>
          {currentWeather?.max}
          °
          </span>
        </p>
      </div>
      </>
    }
    </div>
  );
}
