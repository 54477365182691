import React from 'react';
import { useSelector } from 'react-redux';
import { IContract } from '../../types/contract';
import { IShift } from '../../types/shift';
import { IUser } from '../../types/user';
import TimeLine from '../TimeLine/TimeLine';
import styles from './DayRow.module.scss';

export default function DayRow({
  day,
  shifts = [],
  isToday = false,
  user,
}:{
  day: Date,
  shifts: IShift[],
  isToday?: boolean,
  user: IUser,
}) {
  const { contracts } = useSelector((store: any) => store.contractsReducer);
  const { filters } = useSelector((store: any) => store.usersReducer);
  const currentContract = user && contracts.filter(
    (c: IContract) => c.user === user._id,
  ).find(
    (c: IContract) => {
      const endDate = c?.endDate && new Date(c?.endDate);
      if (c?.endDate && endDate?.getTime() < new Date(filters.startDate)?.getTime()) {
        return null;
      }
      return c;
    },
  );

  const absencesOptions = currentContract?.isAnnualized ? ['paid vacation'] : [];

  const activeShiftCount = shifts.filter(
    (s: IShift) => s.isActive && !absencesOptions.find((a :any) => a === s.activity),
  ).map(
    (s) => s.slices,
  ).flat();
  const hours : number = activeShiftCount?.length ? (activeShiftCount.length * 5) / 60 : 0;
  const minutes = (hours - Math.floor(hours)) * 60;

  const dateFormated = new Intl.DateTimeFormat('fr', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(day);

  return (
    <div className={`${styles.container} ${isToday ? styles.active : ''}`}>
      <div className={styles.date}>
        <p>{dateFormated}</p>
        {hours > 0 && <p className={styles.hours}>
          {Math.floor(hours)}H{minutes > 0 && minutes}
        </p>}
      </div>
      <div
        className={styles.timeline}>
        <TimeLine shifts={shifts} />
      </div>
    </div>
  );
}
