import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FaPen } from 'react-icons/fa';
import { BsCheck } from 'react-icons/bs';

import {
  getStructureAction, updateStructureAction, createStructureAction, getStructurePoolsAction,
} from '../../actions/structures';

import StructureForm from '../../components/Structure/StructureForm';
import StructureInfos from '../../components/Structure/StructureInfos';
import Header from '../../components/Header';

import styles from './Structure.module.scss';
import { SET_USERS_FILTERS } from '../../actions/types';
import { getShiftsListAction } from '../../actions/shift';
import { postUsersFilteredAction } from '../../actions/users';

const Structure = ({ isCreate = false }:{ isCreate?: boolean }) => {
  const [isEdit, setIsEdit] = useState(isCreate);
  const dispatch = useDispatch();
  const { structure } = useSelector((store: any) => store.structureReducer);
  const { filters } = useSelector((store: any) => store.usersReducer);
  const {
    user,
  } = useSelector((store: any) => store.authReducer);

  const id = useParams()?.id;
  useEffect(() => {
    if (id) {
      getStructureAction(dispatch, id);
      getStructurePoolsAction(dispatch, id);
    }
    getShiftsListAction(dispatch);
  }, []);

  const methods = useForm({
    defaultValues: {
      isNextWeeks: false,
    },
  });
  const onSubmit = (data : FieldValues) => {
    const form = { ...methods.watch(), ...data };
    if (structure?._id) {
      updateStructureAction(dispatch, form, structure?._id);
      return setIsEdit(false);
    }
    createStructureAction(dispatch, form);
    return setIsEdit(false);
  };

  useEffect(() => {
    if (structure?._id) {
      methods.reset({ ...structure });
      getStructurePoolsAction(dispatch, structure?._id);
      postUsersFilteredAction(dispatch, { structure: structure?._id, search: '' });
      dispatch({
        type: SET_USERS_FILTERS,
        payload: { ...filters, structure: structure?._id },
      });
    }
  }, [structure]);

  return (
    <div className={styles.container}>
      <Header name={structure?.name || 'Nouveau site'} />
      <div className={styles.content}>
        {user?.role === 'Admin'
          && <>
            {!isEdit
              ? <button className={styles.edit} onClick={() => setIsEdit(true)}>
                <div className={styles.icon}>
                  <FaPen />
                </div>
                <span>MODIFIER LES INFOS</span>
              </button>
              : <button className={styles.edit} onClick={methods.handleSubmit(onSubmit)}>
                <div className={styles.icon}>
                  <BsCheck size={28}/>
                </div>
                <span>Sauvegarder</span>
              </button>
            }
            </>
        }
        <FormProvider {...methods} >
        {isEdit
          ? <StructureForm
              onSubmit={(obj: any) => {
                onSubmit({ ...methods.watch(), ...obj });
                setIsEdit(false);
              }}
          />
          : <StructureInfos
            onSubmit={(obj: any) => onSubmit({ ...methods.watch(), ...obj })}
          />}
        </FormProvider>
      </div>
    </div>
  );
};

export default Structure;
