import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { useController, useWatch, UseControllerProps } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import styles from './input-format-pattern.module.scss';

interface InputFormatPatternProps extends UseControllerProps {
  format?: string;
  mask?: string;
  defaultValue?: string;
  disabled?: boolean;
  inline?: boolean;
  widthLabel?: number;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  className?: string;
}

interface InputFormatPatternRefObject {
  getValue(): string;
  getObject(): Record<string, string>;
}

const InputFormatPattern = forwardRef<InputFormatPatternRefObject, InputFormatPatternProps>(({
  name,
  control,
  format,
  mask,
  rules,
  defaultValue = '',
  disabled = false,
  inline = false,
  widthLabel = null,
  label = null,
  placeholder = '',
  maxLength = '',
  className,
}, ref) => {
  const [valueInput, setValueInput] = useState('');

  const {
    field: {
      // name,
      value = '',
      // ref,
      onChange,
      onBlur,
    },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const inputWatch = useWatch({
    control,
    name,
  });

  useImperativeHandle(ref, () => ({
    getValue: () => valueInput,
    getObject: () => ({ [name]: valueInput }),
    // setValue : (str) => setValueInput(str)
  }));

  useEffect(() => {
    setValueInput(inputWatch);
  }, [inputWatch]);

  const inputProps: Record<string, any> = {};

  if (maxLength) {
    inputProps.maxLength = maxLength;
  }

  return (
    <div className={inline ? `${className ? styles[className] : ''} ${styles['container-input-format-number']} ${styles.inline}` : `${className ? styles[className] : ''} ${styles['container-input-format-number']}`}>
      {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <PatternFormat
        // CONTROLLER -> FIELD PROPS
        name={name}
        getInputRef={ref}
        onBlur={onBlur}
        className={styles.input}
        //
        format={format || ''}
        mask={mask || ''}
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        {...inputProps}
      />
    </div>
  );
});

export default InputFormatPattern;
