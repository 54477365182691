import React from 'react';
import { IoClose } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { SET_USERS_FILTERS } from '../../actions/types';
import { IFilters } from '../../reducers/usersReducer';
import { IPole } from '../../types/structure';
import Icon from '../Icon/Icon';
import styles from './FiltersMobile.module.scss';

const FiltersMobile = ({
  isOpen,
  setIsOpen,
  poles,
  filters,
}:{
  isOpen: boolean
  setIsOpen: (bool: boolean) => void
  poles: IPole[],
  filters: IFilters,
}) => {
  const dispatch = useDispatch();
  const activePoles = poles?.filter((po) => filters?.poles?.find((p: string) => p === po._id));
  const notActivePoles = poles
    ?.filter((po) => !filters?.poles?.find((p: string) => p === po._id));

  function handleChangeFilters(obj: any) {
    dispatch({
      type: SET_USERS_FILTERS,
      payload: {
        ...filters,
        ...obj,
      },
    });
  }

  function handleChange(pole: IPole) {
    let polesFiltered = null;
    if (filters?.poles.find((f : string) => f === pole._id)) {
      polesFiltered = filters?.poles.filter((p:string) => p !== pole._id);
    } else {
      polesFiltered = [...filters.poles, pole._id];
    }
    handleChangeFilters({ poles: polesFiltered });
  }

  return (
    <div className={`${styles.filters} ${isOpen ? styles.active : ''}`}>
      <div className={styles.header}>
        <h4>Filtres</h4>
        <button
          type='button'
          onClick={() => setIsOpen(!isOpen)}
        >
          <IoClose />
        </button>
      </div>
      <div className={styles.content}>
        <label>Filtrer par catégories</label>
        <div className={styles.poles}>
        {activePoles?.map((d: IPole, i: number) => <button
          key={`pole-${i}`}
          onClick={() => handleChange(d)}
          className={styles.active}
          >
          <div
            className={styles.icon}
            style={{ backgroundColor: d.color }}
          >
            <Icon
              selectedIcon={d.icon}
              color="#FFFFFF"
            />
          </div>
          <p>{d.name}</p>
        </button>)}
        {notActivePoles?.map((d: IPole, i: number) => <button
          key={`pole-${i}`}
          onClick={() => handleChange(d)}
          >
          <div
            className={styles.icon}
            style={{ backgroundColor: '#A2B1CE' }}
          >
            <Icon
              selectedIcon={d.icon}
              color="#FFFFFF"
            />
          </div>
          <p>{d.name}</p>
        </button>)}

        </div>

      </div>
      <button
        type='button'
        className={styles.btn}
        onClick={() => setIsOpen(!isOpen)}
        >
        Appliquer les filtres
      </button>
    </div>
  );
};

export default FiltersMobile;
