import { Dispatch } from 'redux';
import { getData, getFile } from '.';
import { GET_EXPORT_LIST, STRUCTURE_ERROR } from './types';

export const exportXlsxAction = async (dispatch :Dispatch, data: any) => {
  const url = '/export';
  const response = await getFile(STRUCTURE_ERROR, url, dispatch, data);

  console.log(response);
  return response;
};

export const getExportLists = async (dispatch :Dispatch) => {
  const url = '/export/list';
  const response = await getData(STRUCTURE_ERROR, url, dispatch, true);

  if (response) {
    dispatch({
      type: GET_EXPORT_LIST,
      payload: response.data.list,
    });
  }
  return response;
};
