import React, { useEffect, useRef, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import InputMedia from '../../lib/HooksFormFields/InputMedia';
import styles from './Structure.module.scss';
import ModalStandalone, { IModalHandle } from '../../lib/ModalStandalone';
import AddPoleModale from '../AddPoleModal/AddPoleModal';
import { IPole, IPool } from '../../types/structure';
import Icon from '../Icon/Icon';
import PoleModal from '../PoleModal/PoleModal';
import AdminModal from '../AdmiModal/AdminModal';

import { IUser } from '../../types/user';
import { UserCard } from '../../pages/UsersList/UsersList';
import PoolModal from '../PoolModal/PoolModal';

const StructureInfos = ({ onSubmit }: { onSubmit: any }) => {
  const { usersList } = useSelector((store: any) => store.usersReducer);
  const { pools, structure } = useSelector((store: any) => store.structureReducer);
  const { watch } = useFormContext<any>();
  const form = watch();
  const modalRef = useRef<IModalHandle | null>(null);
  const [modaleType, setModaleType] = useState('add');
  const [pole, setPole] = useState<any>(null);
  const [pool, setPool] = useState<any>(null);
  const {
    user,
  } = useSelector((store: any) => store.authReducer);

  const admins = structure?._id && usersList.filter((u : IUser) => u.role === 'Owner' && u.structure?._id === structure?._id);

  function handleAddModale() {
    modalRef?.current?.open();
    setModaleType('add');
  }

  function handleAddAdminModale() {
    modalRef?.current?.open();
    setModaleType('admin');
  }

  function handleEditModale(p: IPole) {
    modalRef?.current?.open();
    setModaleType('edit');
    setPole(p);
  }

  function handleEditPoolModale(p: IPool | null = null) {
    modalRef?.current?.open();
    setModaleType('pool');
    setPool(p || { lines: [{ name: '1' }], structure: form._id });
  }

  useEffect(() => {
    if (!modalRef?.current?.isOpen) {
      setModaleType('add');
      setPole(null);
    }
  }, [modalRef?.current?.isOpen]);

  return (
    <div className={`${styles.container} ${user?.role === 'Admin' ? styles.active : ''}`}>
      <div className={styles.bg}/>
      <div className={styles.content}>
        <div className={styles.avatar}>
          <InputMedia
            name="picture"
            media={form?.picture}
            className={'structure'}
            handleChange={onSubmit}
            submit={onSubmit}
            disabled={!form._id || user?.role !== 'Admin'}
          />
          <div className={styles.name}>
            <h1>{form?.name}</h1>
            <p>
              {form?.address}
              {form?.city && <>
                  <br/>
                  {form?.postalCode} {form?.city}
                </>
              }
            </p>
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.infos}>
            <div className={styles.col}>
              <h2>Contacts</h2>
              <div className={styles.field}>
                <p>Téléphone</p>
                <p>{form.phone}</p>
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.title}>
                <h2>Direction</h2>
                {user?.role === 'Admin'
                  && <button
                    type='button'
                    className={styles.add}
                    onClick={() => handleAddAdminModale()}
                  >
                    <div className={styles.icon}>
                    <FaPlus />
                    </div>
                  </button>
                }
              </div>
              <div className={styles.admins}>
                {admins && admins.map((u : IUser) => <UserCard key={u._id} user={u} className={'structure'} />)}
              </div>
            </div>
          </div>
          <div className={styles.pole}>
            <div className={styles.title}>
              <h2>Catégories présentes</h2>
              {user?.role === 'Admin'
                  && <button
                  type='button'
                  className={styles.add}
                  onClick={() => handleAddModale()}
                >
                  <div className={styles.icon}>
                  <FaPlus />
                  </div>
                </button>
              }
            </div>
            <div className={styles.poles}>
            {form?.poles?.map((d: IPole, i: number) => <button
              key={`pole-${i}`}
              type="button"
              onClick={() => handleEditModale(d)}
              >
              <div
                className={styles.icon}
                style={{ backgroundColor: d.color }}
              >
                <Icon
                  selectedIcon={d.icon}
                  color="#FFFFFF"
                />
              </div>
              <p>{d.name}</p>
              {!d.isActive && <p className={styles.archived}>(Archivé)</p>}
            </button>)}
            </div>
          </div>
          <div className={styles.pool}>
            <div className={styles.title}>
              <h2>Équipements</h2>
              {user?.role === 'Admin'
                  && <button
                  type='button'
                  className={styles.add}
                  onClick={() => handleEditPoolModale()}
                >
                  <div className={styles.icon}>
                  <FaPlus />
                  </div>
                </button>
              }
            </div>
            <div className={styles.pools}>
            {pools?.map((d: IPool, i: number) => <button
              key={`pool-${i}`}
              type="button"
              onClick={() => handleEditPoolModale(d)}
              >
              <p>{d.name}</p>
              <p>{d?.lines?.length || 0} activité{d?.lines?.length === 1 ? '' : 's'}</p>
            </button>)}
            </div>
          </div>
        </div>
      </div>
      <ModalStandalone ref={modalRef}>
        {modaleType === 'add' && <AddPoleModale
          createPole={() => handleEditModale({
            name: '',
            icon: 'IoIosAlbums',
            color: '',
            activities: [],
            isActive: true,
          })}
          close={() => modalRef?.current?.close()}
          onSubmit={() => {
            onSubmit();
            modalRef?.current?.close();
          }}
        />}
        {modaleType === 'pool' && pool
          && <PoolModal
            pool={pool}
            close={() => modalRef?.current?.close()}
          />
        }
        {modaleType === 'edit' && pole
          && <PoleModal
            pole={pole}
            close={() => modalRef?.current?.close()}
            onSubmit={(obj: any) => {
              onSubmit(obj);
              modalRef?.current?.close();
            }}
          />
        }
        {modaleType === 'admin'
          && <AdminModal
            close={() => modalRef?.current?.close()}
          />
        }
      </ModalStandalone>
    </div>
  );
};

export default StructureInfos;
