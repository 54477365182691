import React, { useState } from 'react';

import styles from './ExportModal.module.scss';
import ExportAdmin from '../ExportAdmin/ExportAdmin';
import ExportCompare from '../ExportCompare/ExportCompare';

const ExportModal = () => {
  const [tab, setTab] = useState<string>('admin');

  return (
    <div className={styles.modale}>
      <h2>
       Export
      </h2>
      <div className={styles.tabs}>
        <button
          type='button'
          className={tab === 'admin' ? styles.active : ''}
          onClick={() => setTab('admin')}
        >
          Export administratif
          <div className={styles.bar}/>
        </button>
        <button
          type='button'
          className={tab === 'compare' ? styles.active : ''}
          onClick={() => setTab('compare')}
        >
          Export comparaison
          <div className={styles.bar}/>
        </button>
      </div>
      <div className={styles.tab}>
      {tab === 'admin' && <ExportAdmin />}
      {tab === 'compare' && <ExportCompare />}
      </div>
    </div>
  );
};

export default ExportModal;
